.ProductGrid {
  h2 {
    text-align: center;
    font-size: 20px;
    margin: 0 0 20px;
    text-transform: uppercase;
    font-weight: 600;
    &:after {
      content: ':';
    }
  }
  .group {
    margin-bottom: 30px;
  }
  .products {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(2, minmax(min-content, 400px));
    justify-content: center;
  }
  .color-divider {
    margin-top: 43px;
    width: 250px;
  }
  .Product {
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 300px;
    color: #fff;
    position: relative;
    padding: 15px;
    display: flex;
    flex-direction: column;
    &.special {
      &::before,
      &::after {
        content: ' ';
        display: block;
        width: 50px;
        height: 50px;
        position: absolute;
        background-image: url(../../../images/star.png);
        background-repeat: no-repeat;
        background-size: 50px;
        opacity: 0.93;
        top: -28px;
      }
      &::before {
        left: -25px;
      }
      &::after {
        right: -25px;
      }
    }
    &.index-2, &.full {
      grid-column: 1 / 3;
      .blackout {
        background: linear-gradient(157.5deg, #0009, #0002);
      }
    }
    .blackout {
      background: linear-gradient(157.5deg, #0009, #0001);
      flex: 1 1 100%;
      padding: 15px 15px 30px;
    }
    .top {
      display: flex;
      justify-content: space-between;
      .right {
        margin-left: 5px;
        min-width: 46px;
      }
      .Button {
        border-radius: 50%;
        padding: 13px;
        margin: 0;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .price {
        font-size: 20px;
        font-weight: 600;
        margin-top: 5px;
      }
    }
    .top + p {
      margin: 15px 0 0;
    }
    h3 {
      margin: 0;
      font-size: 24px;
      line-height: 24px;
      font-weight: 600;
    }
    h3 + p {
      margin: 0;
      padding: 0;
    }
    small {
      font-size: 16px;
      color: #ccc;
    }
    ul {
      font-size: 18px;
      margin: 15px 0 0 0;
      padding: 0 0 0 0px;
      max-width: 80%;
      li {
        list-style: none;
        line-height: 1.6;
      }
      li:before {
          content: '';
          display: inline-block;
          height: 15px;
          width: 15px;
          margin-right: 10px;
          background-size: 40px;
          background-image: url(../../../assets/carwash-icon-shine-white.svg);
          background-repeat: no-repeat;
      }
    }
  }
  .index-0 {
    .top .Button {
      background-color: #50bda3;
    }
  }
  .index-1 {
    .top .Button {
      background-color: #1894a2;
    }
  }
  .index-2 {
    .top .Button {
      background-color: #4468f1;
    }
  }
  .ProductInfo {
    position: absolute;
    right: 20px;
    bottom: 20px;
    margin-top: 0;
  }
}

.ProductGrid_Mobile {
  padding-top: 40px;
  margin-left: -20px;
  margin-right: -20px;
  .product {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #eee;
    &.selected {
      background: #4668f433;
      color: #000;
    }
    .main {
      flex: 1 1 70%;
    }
    .right {
      flex: 0 0 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .name {
      font-size: 20px;
    }
    .price {
      color: #999;
      margin-top: 15px;
    }
    .Button {
      margin-top: 0;
    }
  }
}

@media screen and (min-width: 1700px) {
  .ProductGrid {
    grid-template-columns: repeat(3, minmax(min-content, 500px));
  } 
}

@media screen and (max-width: 767px) {
  .ProductGrid {
    margin-left: -5px;
    margin-right: -5px;
    h2 {
      font-size: 18px;
      margin-bottom: 10px;
    }
    .products {
      grid-template-columns: 1fr;
      gap: 25px;
    }
    .Product {
      padding: 0;
      min-height: 155px;
      .blackout {
        background: linear-gradient(157.5deg, #0009, #0002);
        padding-bottom: 15px;
      }
      &.index-2, &.full {
        grid-column: auto;
      }
      .top {
        h3 {
          font-size: 21px;
        }
        .price {
          font-size: 18px;
          margin-top: 2px;
        }
        .Button {
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .top + p {
        margin-top: 5px;
      }
      ul {
        font-size: 17px;
        margin-top: 10px;
        li {
          line-height: 1.3;
        }
        li:before {
          height: 15px;
          width: 15px;
          background-size: 40px;
          transform: translateY(3px);
        }
      }
    }
  } 
}