.PlanForm {
  max-width: 500px;
  label {
    flex: 0 0 120px;
  }
  .header {
    margin-top: 20px;
  }
  .feature {
    padding: 20px;
    box-shadow: 0 0 4px -1px #0002;
  }
  .feature + .feature {
    margin-top: 20px;
  }
  .feature-header {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    button img {
      width: 20px;
      height: 20px;
    }
  }
  textarea {
    min-height: 200px;
  }
}
