.App {
  padding-top: 55px;
  &.show-covid-bar {
    padding-top: 200px;
    @media screen and (max-width: 940px) { 
      padding-top: 135px; 
      .CustomerHome {
        padding-top: 135px;
      }
    }
    .BookingPage, .UserSignupPage, .CustomerHome {
      .summary {
        top: 200px;
        @media screen and (max-width: 940px) { top: 135px; }
      }
    }
    .hero a.signup.sticky {
      top: 200px;
      @media screen and (max-width: 940px) { top: 135px; }
    }
  }
}

.App.landing {
  padding-top: 0;
  .Nav, .Nav a {
    transition: all 200ms ease-in-out;
  }
  &.scrolled .Nav {
    box-shadow: 0 0 4px 4px #0000000a;
    border-bottom-color: #0000;
  }
  &.is-mobile .Nav {
    .right {
      color: #151515;
    }
  }
  &:not(.scrolled):not(.is-mobile) .Nav {
    .right a:not(.button):not(.primary) {
      color: #fff;
      text-shadow: 1px 1px 0px #555;
    }
  }
  &:not(.scrolled) .Nav {
    background: #0000;
    border-bottom-color: #0000;
    box-shadow: none;
    border-bottom-color: #0000;
  }
}

.App.loading {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 125px;
    height: auto;
    transform: translateY(-40px);
  }
}

@media screen and (max-width: 674px) {
  .App.logged-in {
    padding-top: 0;
  }
}
