.Button.ActionMenuTrigger {
  img {
    width: 22px;
    height: 22px;
  }
  &:focus, &:focus img {
    appearance: none;
    background: none;
  }
}

.ActionMenu {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .mobile-close {
    display: none;
  }
  .menu {
    position: absolute;
    background: #fdfdfd;
    box-shadow: 0 0 13px -1px #0003;
    border-radius: 3px;
    padding: 5px 0;
    animation: expand-down 100ms ease-in-out;
    .Button, a.button {
      padding: 8px 20px;
      color: #555;
      background: #0000;
      text-decoration: none;
      display: flex;
      align-items: center;
      &:hover {
        color: #555;
        background: #f3f3f3;
      }
      &:active, &:focus {
        background: #f0f0f0;
      }
      span {
        margin-left: 7px;
      }
      img {
        width: 18px;
        height: 18px;
      }
      label {
        margin-left: 25px;
      }
    }
  }
}

@media screen and (max-width: 674px) {
  .ActionMenu {
    .menu {
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
      position: fixed;
      animation: fade-in 200ms ease-in-out;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      .mobile-close {
        display: block;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 13px;
        .Button {
          padding: 8px;
          width: auto;
          border-bottom: none;
        }
      }
      .Button, a.button {
        font-size: 18px;
        padding: 10px 20px;
        width: 100%;
        border-bottom: 1px solid #eee;
      }
    }
  }
}
