.AddButton {
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: #4668f4;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 8px 5px #0002;
  transition: all 100ms ease-in-out;
  &:not(:disabled):hover {
    background: darken(#4668f4, 5%);
    box-shadow: 0 0 8px 5px #0003;
  }
  &:not(:disabled):active, &:not(:disabled):focus {
    background: darken(#4668f4, 10%);
  }
  img {
    width: 16px;
    height: 16px;   
  }
}