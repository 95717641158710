@keyframes slide-down {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  from {
    transform: translateY(10px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes expand-down {
  from {
    transform: scaleY(0);
    transform-origin: top;
  }
  to {
    transform: scaleY(1);
    transform-origin: top;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in-2 {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@keyframes modal-slide-down {
  from {
    top: 33%;
  }
  to {
    top: 35%;
  }
}

@keyframes mobile-modal-slide-down {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes grow {
  from {
    transform: scale(0.85);
    opacity: 0.5;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-y {
  from {
    transform: scaleY(0);
    transform-origin: top;
  }
  to {
    transform: scaleY(1);
    transform-origin: top;
  }
}

@keyframes placeholder {
  0% {
    background: #fcfcfc;
  }
  50% {
    background: #f2f2f2;
  }
  100% {
    background: #fcfcfc;
  }
}