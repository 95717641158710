
.ManageLocationInfo {
  padding: 20px 50px;
  .TitleBar {
    .BackButton {
      order: 1;
      height: 30px;
      padding: 0 12px;
      margin-right: 5px;
    }
    h2 {
      order: 2;
    }
    .AddButton {
      order: 3;
    }
  }
  .car, .address {
    padding: 20px;
    max-width: 800px;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    color: #777;
    display: grid;
    grid-template-columns: 1fr 40px;
    column-gap: 15px;
    &:hover:not(.selected) {
      color: #555;
    }
    &.selected {
      background: #4668f409;
      color: #4668f4;
    }
  }
  .addresses {
    margin-top: 50px;
  }
}

@media screen and (max-width: 674px) {
  .ManageLocationInfo {
    padding: 0;
    .TitleBar, .CarForm, .AddressForm {
      padding: 0 20px;
      .ActionBar {
        padding: 40px 0;
      }
    }
    & > .ActionBar {
      padding-left: 20px;
    }
  } 
}