.Toast {
  position: fixed;
  z-index: 5000;
  top: 30px;
  left: 0;
  right: 0;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  pointer-events: none;
  animation: slide-down 100ms ease-in-out;
  .content {  
    background-color: #00ffcc;
    color: #555;
    padding: 8px 25px;
    border-radius: 2px;
    box-shadow: 0 0 6px 1px #0001;
    font-size: 16px;
    white-space: pre-wrap;
    pointer-events: all;
    white-space: pre-line;
    @media screen and (max-width: 940px) {
      font-size: 17px;
    }
  }
}