.EmployeeDetail {
  .invoice {
    display: flex;
    max-width: 800px;
    & > * {
      padding: 15px 25px;
      flex: 1 1 30%;
    }
    .download {
      text-align: right;
    }
    img {
      width: 17px;
      height: 17px;
    }
  }
  .invoice + .invoice {
    border-top: 1px solid #f0f0f0;
  }
  .appointment-info {
    display: grid;
    grid-template-columns: 250px 300px;
    & > div {
      padding: 10px 0;
      & > span:first-child {
        color: #999;
      }
    }
    @media screen and (max-width: 600px) {
      display: block;
      padding: 15px 0;
      & + .appointment-info {
        border-top: 1px solid #eee;
      }
      & > div {
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 2fr;
      }
    }
    
  }
  .change-plan {
    margin-top: 0;
  }
  .work-schedule {
    .ActionBar {
      button {
        padding: 4px 20px;
      }
    }
  }
}

.BasicInfo {
  .header {
    padding-top: 75px;
  }
  .ActionBar {
    padding-bottom: 0;
  }
  .Form {
    max-width: 500px;
    label {
      flex: 0 0 180px;
    }
  }
}

.ChangePlan {
  .plans {
    display: flex;
    flex-wrap: wrap;
  }
  .plan {
    margin: 0 20px 20px 0;
    padding: 30px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background: #fff;
    transition: all 100ms ease-in-out;
    box-shadow: 0 0 20px 10px #00000004;
    &:hover {
      box-shadow: 0 0 20px 10px #0001;
      transform: translateY(-1px);
    }
    &.active {
      background: #dbf8ff;
    }
    .price {
      margin-top: 20px;
    }
  }
  .Field {
    max-width: 500px;
    label {
      flex: 0 0 125px;
    }
  }
}
