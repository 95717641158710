.UserSignupPage2 {
  padding: 24px 20px 100px;
  background: #fff;
  margin: auto;
  min-height: 100vh;
  position: relative;

  .DiscreteProgessBar {
    margin: 0 auto 20px;
    max-width: 800px;
    transform: translateY(-40px);
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
    }
    @media (max-width: 674px) {
      max-width: 100vw;
      margin-left: -20px;
      margin-right: -20px;
      padding: 0 15px;
      transform: none;
    }
    .step {
      min-width: 55px;
    }
  }
  .PackageDealForm {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    padding: 30px 15px;
    margin: 30px 0;
    display: flex;
    align-items: flex-end;
    input {
      border: 1px solid #bbb !important;
      border-right: none !important;
      border-top-left-radius: 3px !important;
      border-bottom-left-radius: 3px !important;
      height: 44px;
    }
    button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 44px;
    }
  }
  .PaymentForm {
    max-width: 400px;
    margin: auto;
    padding-bottom: 50px;
  }
  .container {
    transition: transform 200ms, opacity 150ms;
    transform: translateX(0);
    opacity: 1;
    &.hiding {
      transform: translateX(-500px);
      opacity: 0;
    }
  }
  form {
    max-width: 400px;
    margin: auto;
  }
  .Input textarea {
    min-height: 120px;
  }
  h1 {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    position: relative;
    text-align: center;
    .BackButton {
      position: absolute;
      top: 5px;
      left: 0;
      label {
        margin-left: 0;
      }
      img {
        width: 12px;
        height: 22px;
      }
    }
  }
  .product-step {
    
  }
  .addon-step {
    
  }
  .appointment-step {

  }
  .location-step {
    form {
      margin-top: 30px;
    }
  }
  .vehicle-step {
    
  }
  .confirm-step {
    .VehiclePreview, .LocationPreview, .Terms, .Cart {
      max-width: 500px;
      margin: auto;
      border: 1px solid #ddd;
      border-radius: 3px;
    }
    .LocationPreview {
      margin-top: 20px;
    }
    .Cart {
      margin-top: 20px;
      padding: 15px 15px 40px;
    }
  }
  .account-step {
    h3, h4 {
      max-width: 650px;
      margin: 0 auto;
      padding: 0 15px;
      text-align: center;
    }
    h4 {
      margin-top: 25px;
      margin-bottom: 15px;
    }
    p {
      margin: 0;
    }
    p + p {
      margin-top: 10px;
    }
  }
  .payment-step {
    .Cart {
      max-width: 500px;
      margin: auto;
      border: 1px solid #ddd;
      border-radius: 3px;
    }
    .Terms {
      max-width: 500px;
      margin: 50px auto;
      border: 1px solid #ddd;
      border-radius: 3px;
      padding: 15px 15px 30px;
      font-size: 14px;
      color: #aaa;
    }
  }
  .done-step {
    form {
      margin-top: 80px;
    }
  }
  .password-card {
    margin-top: 50px;
    border: 1px solid #eee;
    border-radius: 3px;
    padding: 30px;
    p {
      margin-top: 0;
    }
    button {
      width: 100%;
    }
  }
  .product-header {
    margin-top: 60px;
    font-size: 18px;
  }
  .footer {
    margin-top: 30px;
    text-align: center;
    button + button {
      margin-left: 15px;
    }
    button.secondary {
      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
      }
    }
  }
  .add-ons {
    text-align: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    row-gap: 20px;
    column-gap: 20px;
  }
  .StripeElement {
    margin-top: 40px;
  }
  .image-wrapper {
    .image {
      width: 70px;
      height: 70px;
      border-radius: 35px;
      background-size: 514px 556px;
      background-repeat: no-repeat;
      margin: auto;
    }
  }
  .products {
    display: grid;
    justify-content: center;
    grid-row-gap: 75px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 350px));
  }
  .toggle {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: #555;
    }
  }
  .step-description {
    color: #888;
    text-align: center;
    margin-bottom: 0;
  }
  sup {
    font-weight: 400;
    color: #818283;
    font-size: 14px;
    position: relative;
    top: 2px;
  }
  sub {
    position: absolute;
    padding: 3px 40px;
    top: 23px;
    right: -34px;
    font-size: 13px;
    font-weight: 600;
    transform: rotate(45deg);
    background: #4668f4;
    color: #fff;
  }
  .disclaimer {
    margin-top: 60px;
    font-size: 13px;
    color: #c5c5c5;
  }
  .ProductGrid {
    margin-top: 30px;
  }
  .car-info,
  .account,
  .payment,
  .appointment {
    padding-top: 40px;
    padding-bottom: 40px;
    max-width: 800px;
    margin: auto;
  }
  input:not([type='checkbox']):not([type='radio']),
  select,
  textarea {
    background: none;
    border: none;
    border-bottom: 1px solid #bbb;
    border-radius: 0;
    color: #555555;
    &:focus {
      border-color: #4668f4;
    }
  }
  .Input:not(.checkbox).has-value input:focus + label,
  .Input.has-value textarea:focus + label {
    color: #4668f4;
  }
  textarea {
    border: 1px solid #bbb;
  }
  .StripeForm {
    background: none;
  }
  .communication-info {
    margin-top: 40px;
    font-weight: 600;
  }
  .appointment {
    .detail-form {
      max-width: 800px;
    }
    .footer {
      padding: 50px 0 30px;
    }
  }
  .calendar-wrapper {
    max-width: 800px;
    margin: auto;
  }
  .Calendar {
    height: 700px;
    margin-top: 30px;
    button {
      margin-top: 0;
    }
    .rbc-toolbar {
      position: relative;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0;
      .rbc-btn-group {
        button {
          border: none;
        }
      }
      .rbc-btn-group:first-child button:first-child {
        display: none;
      }
      .rbc-toolbar-label {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        @media (min-width: 941px) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      button:active, 
      button.rbc-active {
        box-shadow: none;
        background-color: #0000;
      }
      button:focus {
        background-color: #0000;
      }
    }
  }
  .calendar-placeholder {
    padding: 30px 0 20px;
    div {
      animation: placeholder 2s ease-in-out infinite;
    }
    .div-1 {
      height: 33px;
      margin-bottom: 10px;
    }
    .div-2 {
      height: 16px;
      margin-bottom: 6px;
    }
    .div-3 {
      height: 678px;
    }
  }
  .input-row {
    margin-top: 15px;
    display: flex;
    .city {
      flex: 3 3 50%;
    }
    .state, .zip {
      flex: 1 1 25%;
      margin-left: 10px;
    }
    .Input + .Input {
      margin-top: 0;
    }
    & + .Input {
      margin-top: 15px;
    }
  }
  input[name="address"] + label {
    white-space: nowrap;
  }
  .terms {
    margin: 10px 0 20px;
    color: #bbb;
    font-size: 85%;
  }
  .summary {
    padding: 8px 30px;
    background: #ffffffe3;
    backdrop-filter: blur(3px);
    box-shadow: 0 0 4px 4px rgba(0,0,0,0.04);
    position: fixed;
    top: 55px;
    left: 0;
    right: 0;
    z-index: 10;
    @media screen and (min-width: 675px) {
      div {
        max-width: 200px;
        margin: auto;
      }
    }
  }
  .vehicle-info-divider {
    margin: 80px auto 70px;
  }
  .cart-divider {
    margin: 60px auto 50px;
  }
  .terms-divider {
    margin: 60px auto;
  }
  .form-section-vehicle {
    margin-top: 0px;
  }
  .service-area {
    padding-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
      width: 100%;
      max-width: 600px;
    }
    p {
      margin: 0 0 15px;
      padding: 0;
      text-align: center;
    }
  }
  .map-wrapper {
    max-width: 800px;
    margin: auto;
  }
  .map-info {
    font-size: 16px;
    font-weight: 600;
    color: #888;
    text-align: center;
    margin-bottom: 10px;
  }
  .done-message {
    font-size: 18px;
    line-height: 1.7;
    max-width: 600px;
    margin: auto;
  }
}

.UserSignupPage {
  .done-message {
    font-size: 18px;
    line-height: 1.7;
    max-width: 600px;
    margin: auto;
  }
}

@media screen and (max-width: 499px) {
  .UserSignupPage2 {
    .DiscreteProgessBar {
      margin-top: 5px;
      margin-bottom: 20px;
    }
    h1 {
      margin-bottom: 10px;
    }
    .ProductGrid {
      margin-top: 30px;
    }
    .car-info,
    .account,
    .payment,
    .appointment {
      margin-left: -20px;
      margin-right: -20px;
      padding: 40px 15px 0;
    }
    .footer {
      margin-bottom: 60px;
    }
    .disclaimer {
      margin-bottom: 60px;
    }
  }
}

@media screen and (max-width: 767px) {
  .UserSignupPage2 {
    h1 {
      font-size: 25px;
    }
    .step-description {
      font-size: 16px;
    }
    .account-step {
      h3 {
        p {
          font-size: 18px;
          line-height: 1.2;
        }
      }
    }
  }
}

@media screen and (max-width: 940px) {
  .UserSignupPage2 {
    padding-top: 64px;
    .Calendar .rbc-toolbar {
      .rbc-btn-group:first-child {
        order: 0;
        margin-top: 0;
      }
      .rbc-toolbar-label {
        text-align: right;
        padding-right: 0;
        font-size: 15px;
      }
    }
    .appointment .Cart {
      padding-left: 15px;
      padding-right: 15px;
    }
    .detail-form form {
      padding-left: 15px;
      padding-right: 15px;
    }
    .PaymentForm {
      padding-left: 0;
      padding-right: 0;
      form {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .Cart {
      padding: 15px 15px 30px;
    }
  }
}

.form-header {
  background: rgb(53, 216, 142);
  background: linear-gradient(128deg, rgba(53, 216, 142, 1) 0%, rgba(57, 168, 235, 1) 100%);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 2rem 0;
  color: #fff;
  @media screen and (max-width: 499px) {
    padding: 1.2rem 0;
  }

  h1 {
    display: block;
    padding: 0;
    margin: 0;
    margin: 0 auto;
    text-shadow: 1px 1px 0 #1475ab;
  }

}

.detail-form {
  background: #fff;
  max-width: 500px;
  margin: 0 auto 70px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);

  h3 {
    font-size: 19px;
  }

  form {
    padding: 2rem;

    input {
      color: #6a6a6a;
    }
  }
}
