.CollapsibleSection {
  .title {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    user-select: none;
    &:hover {
      background: #f8f8f8;
    }
    div {
      font-weight: 600;
      font-size: 16px;
      margin-left: 10px;
    }
    button {
      transition: all 100ms ease-in-out;
      transform: rotate(0);
      img {
        width: 8px;
        height: 10px;
      }
    }
  }
  .body {
    padding: 20px 10px;
    animation: scale-y 100ms ease-in-out;
  }
  &.open {
    .title {
      button {
        transform: rotate(90deg);
      }
    }
  }
}

@media screen and (max-width: 674px) {
  .CollapsibleSection {
    border: 1px solid #eee;
    margin-left: -20px;
    margin-right: -20px;
    border-left: none;
    border-right: none;
    .title {
      padding: 10px;
      &:hover, &:active, &:focus {
        background: #0000;
      }
      div {
        font-size: 18px;
      }
    }
    .body {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}