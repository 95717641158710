.VehiclePreview {
  background-color: #f8f8f8;
  .imgtext {
    padding: 15px;
    width: 100%;
    height: 200px;
  }
  .img-wrapper {
    width: 100%;
    padding: 20px;
  }
  img {
    display: block;
    width: 100%;
    max-height: 210px;
    object-fit: contain;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }  
  .bottom {
    background-color: #fff;
    padding: 15px;
  }
  .vehicle, .stall-info {
    padding-bottom: 10px;
  }
  .stall-info {
    white-space: pre-line;
  }
}
