.why-wait {
  position: relative;

  .left {
    background: #f1f1f1;
    padding: 100px 0;
    @media screen and (max-width: 768px) {
      display: none !important;
    }
    @media screen and (min-width: 769px) {
      height: 500px;
    }
  }

  .right {
    height: 500px;
    background: rgb(211, 38, 70);
    //background: linear-gradient(135deg, rgba(77, 139, 231, 1) 0%, rgba(44, 88, 231, 1) 100%);
    background: linear-gradient(135deg, rgba(33, 33, 33, 1) 0%, rgba(0, 0, 0, 1) 100%);
  }

  .dirty-car {
    width: 40%;
    max-width: 450px;
    position: absolute;
    left: 50%;
    bottom: -50px;
    transform: translate(-50%, 0);
    display: flex;
    align-items: baseline;
  }

  img {
    height: auto;
    width: 100%;
  }

  .pulse {
    height: 18px;
    width: 18px;
    position: absolute;
    top: 13%;
    background: #ffffff;
    z-index: 10;
    border-radius: 50%;
    padding: 12px;
    display: block;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.8);
    -webkit-animation: pulse-white 2s ease-out infinite;
    -moz-animation: pulse-white 2s ease-out infinite;
    animation: pulse-white 2s ease-out infinite;

    &:hover {
      animation: none;
    }

    &.one {
      left: 19%;
    }

    &.two {
      left: 30%;
    }

    &.three {
      left: 41%;
    }

    &.four {
      left: 52%;
    }

    &.five {
      left: 63%;
    }

    &.six {
      left: 74%;
    }

    .inner-circle {
      background: #2c58e7;
      height: 12px;
      width: 12px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      transition: 0.3s all ease;
    }

    &.active .inner-circle {
      background: #d02d36;
    }
  }
}

.why-wait .left {
  display: flex;
  align-items: center;

  .inner-content {
    padding-left: 3rem;
    @media screen and (max-width: 769px) {
      text-align: center;
    }

    h3,
    h4 {
      color: #151515;
      font-weight: 800;
      font-size: 30px;
      margin: 0;
      line-height: 30px;
    }

    h4 {
      font-size: 24px;
      font-weight: 600;
    }

    p {
      margin: 0.5rem 0;
      width: 50%;
      font-size: 18px;
    }
  }
}

.why-wait .right {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .inner-content {
    padding-right: 3rem;
    width: 50%;

    h3 {
      color: #ffffff;
      font-weight: 800;
      font-size: 30px;
      margin: 0;
      line-height: 30px;
      padding: 0;
    }

    h4 {
      font-size: 24px;
      font-weight: 600;
    }

    p {
      color: #ddd;
      margin: 20px 0 0;
      padding: 0;
      line-height: 1.7;
      font-size: 18px;
    }
  }

  .service-one,
  .service-two,
  .service-three,
  .service-four,
  .service-five,
  .service-six {
    display: none;

    &.active {
      display: block;
    }

    &.active.showService {
      animation-duration: 0.3s;
      animation-name: fadeAndScale;
      animation-timing-function: cubic-bezier(0.71, 0.55, 0.62, 1.57);
    }
  }
}

.why-wait {
  @media screen and (max-width: 769px), print {
    .left {
      h3,
      h4,
      .inner-content p {
        padding: 0.25rem 0.5rem;
        width: 100%;
      }

      .inner-content {
        width: 200px;
        padding: 0;
        margin: auto;
      }
    }

    .right {
      .inner-content {
        padding: 0;
        width: 100%;
        padding: 0 1rem;
        margin-bottom: 2rem;
        transform: translateY(-40px);
      }

      h3,
      p {
        text-align: center;
        padding: 0.25rem 0.5rem;
        width: 100%;
      }
    }

    .dirty-car {
      width: 80%;
      position: absolute;
      left: 50%;
      bottom: -70px;
      transform: translate(-50%, 0);
      display: flex;
      align-items: baseline;
    }
  }
}

.WhyWait {
  margin-top: 20vh;
  @media screen and (min-width: 1900px) {
    .left, .right {
      justify-content: center;
      .inner-content {
        width: 40%;
        padding: 0 !important;
      }  
    }
    .right .inner-content {
      transform: translateX(50px);
    }
  }
}  

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@-webkit-keyframes pulse-white {
  0% {
    -webkit-transform: scale(1);
    box-shadow: 0 0 rgba(255, 255, 255, 1);
  }

  50% {
    -webkit-transform: scale(1.1);
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-transform: scale(1);
    box-shadow: 0 0 rgba(255, 255, 255, 0);
  }
}

@-moz-keyframes pulse-white {
  0% {
    -moz-transform: scale(1);
    box-shadow: 0 0 rgba(255, 215, 0, 1);
  }

  50% {
    -moz-transform: scale(1.1);
    box-shadow: 0 0 0 8px rgba(255, 215, 0, 0);
  }

  100% {
    -moz-transform: scale(1);
    box-shadow: 0 0 rgba(255, 215, 0, 0);
  }
}

@keyframes pulse-white {
  0% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 rgba(255, 255, 255, 1);
  }

  50% {
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0);
  }

  100% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes fadeAndScale {
  from {
    opacity: 0;
    transform: scale(0.9, 0.9);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}
