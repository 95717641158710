.Search {
  position: relative;
  display: flex;
  align-items: center;
  input {
    height: 38px;
    flex: 1 1 400px;
    min-width: 400px;
    border: 1px solid #eee !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    box-shadow: none !important;
    &.empty + button.clear {
      display: none;
    }
  }
  button.clear {
    position: absolute;
    top: 13px;
    right: 52px;
    img {
      width: 11px;
      height: 11px;
    }
  }
  button.search {
    background: #fff;
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eee;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &:hover {
      background: #f8f8f8;
    }
    &:active {
      background: #f5f5f5;
    }
  }
}
