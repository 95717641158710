.Ratings {
  background: #f3f3f3;
  margin: 5rem 0 0 0;
  padding: 3rem;

  .logo {
    img {
      width: 130px;
      display: inline-block;
      margin-right: 5px;
    }
  }

  .container {
    background: #f5f5f5;
    padding: 5rem 0 0 0;
  }

  .extra-padding {
    padding: 0 5rem;
  }

  .custom-image img {
    display: block;
  }
  .custom-card {
    padding: 10%;
  }
  .custom-card p {
    color: #999;
  }
  .card-container {
    margin: auto;
  }
  .title {
    margin-bottom: -0.5rem !important;
    float: left;
  }
  .icon-google {
    float: left;
    margin-left: 50%;
  }
  .rating-img {

  }

  .rating-review {
    width: 50%;
    float: left;
    margin-top: 5%;
    margin-bottom: 10%;
  }
  .icon-testimonials {
    float: left;
    width: 15%;
    margin-left: 28%;
    margin-top: 5%;
    margin-bottom: 10%;
  }
  .review-image {
    border-radius: 50%;
    width: 50%;
  }
  .custom-image {
    position: absolute;
    top: -110px;
    left: 15px;
  }
  .simple-Ratings-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .simple-Ratings {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    margin: 3rem 0 1.5rem 0;
  }

  .reviews-column {
    display: flex;
    justify-content: center;
  }

  .column.step {
    text-align: center;

    span {
      display: inline-block;
      text-align: center;
      width: 100%;
      font-size: 100px;
      line-height: 1em;
      font-weight: 700;
      color: #edebeb;
      background: transparent;
      background: -moz-linear-gradient(
        top,
        #edebeb 0%,
        #f7f6f6 74%,
        rgba(255, 255, 255, 0.87) 100%
      );
      background: -webkit-linear-gradient(
        top,
        #edebeb 0%,
        #f7f6f6 74%,
        rgba(255, 255, 255, 0.87) 100%
      );
      background-clip: border-box;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
      text-fill-color: transparent;
      position: relative;
      bottom: -14px;
      z-index: 1;
    }
  }

  svg {
    height: 50px;
    width: 50px;
    fill: #616567;
    position: relative;
    z-index: 2;
    margin-bottom: 1rem;
    margin-top: -1rem;
  }

  p {
    color: #616567;
  }

  @media screen and (min-width: 769px), print {
    .simple-Ratings-col {
      .column {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      img {
        height: 50px;
      }
    }
  }

  @media screen and (max-width: 768px), print {
    padding: 0;

    .container {
      padding: 0;
    }

    .extra-padding {
      padding: 0 1rem;
    }

    .simple-Ratings {
      line-height: auto;
      margin: 0;
    }

    .simple-Ratings-col {
      padding: 45px 15px 25px;
      margin-bottom: 0 !important;
      .column {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .rating-img {
        height: 35px;
      }
      h1 {
        margin-top: 20px;
        font-size: 23px;
        font-weight: 500;
      }
    }

    .reviews-wrapper {
      width: 100vw;
      overflow-x: auto;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      padding-bottom: 60px;
    }
    img.reviews {
      width: 1062px;
      max-width: none;
    }
  }
}
