.Dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0014;
  z-index: 1000;
  .close-x {
    position: absolute;
    top: 20px;
    right: 20px;
    &:hover, &:active, &:focus {
      background: #0000 !important;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  .content {
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border-radius: 3px;
    animation: modal-slide-down 250ms ease-in-out;
    color: #555;
    &.small {
      max-width: 400px;
    }
    &.medium {
      max-width: 700px;
    }
    &.large {
      max-width: 1000px;
    }
    .header {
      padding: 20px;
      font-size: 26px;
      font-weight: 600;
    }
    .body {
      padding: 5px 20px;
      max-height: calc(100vh - 220px);
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
    .footer {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button + button {
        margin-left: 25px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .Dialog {
    .content {
      width: calc(100vw - 10px);
      height: calc(100% - 10px);
      top: 5px;
      left: 5px;
      transform: none;
      animation: mobile-modal-slide-down 250ms ease-in-out;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      .body {
        max-height: none;
        flex: 1 1 70%;
      }
    }
  } 
}