.ScheduleMyAppt {
  background-color: #ffffff;
  display: flex;
  .left, .right {
    padding: 20px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right {
    background-color: rgb(69, 105, 242);
    display: flex;
    align-items: center;
  }
  h1 {
    color: #111;
    font-weight: 600;
    font-size: 28px;
    line-height: 28px;
    margin: 0 auto;
    span {
      color: #3b52e4;
    }
  }
  .logo {
    img {
      width: 130px;
      display: inline-block;
      margin-right: 5px;
    }
  }
  a {
    display: inline-block;
    background: #3b52e4;
    border: 2px solid #3b52e4;
    border-radius: 3px;
    padding: 0.4rem 1.2rem;
    font-size: 14px;
    font-weight: 600;
    margin-top: 0;
    position: relative;
    transition: color 0.3s ease;
    z-index: 1;
    padding: 0.75rem 1.5rem;
    letter-spacing: 1px;
    background: none;
    color: #eee;
    border: 1px solid #eee;
    &:active, &:hover, &:focus {
      background: none;
      border: 1px solid #fff;
      color: #fff;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    margin-top: 170px;
    .left, .right {
      padding: 30px 20px;
    }
  }
  @media (min-width: 769px) {
    height: 150px;
    .right {
      clip-path: polygon(0 0, 130px 100%, 100% 100%, 100% 0);
    }
  }
}
