.StripeForm {
  &.saving {
    opacity: 0.5;
    pointer-events: none;
    * {
      pointer-events: none !important;
      cursor: default;
    }
  }

  background-color: #fff;

  input:not([type='checkbox']):not([type='radio']), select, textarea {
    box-shadow: none;
    background: none;
    border: none;
    &:active, &:focus {
      border: none;
    }
  }

  * {
    font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  .row {
    display: flex;
    margin-bottom: 15px;
  }

  .field {
    position: relative;
    width: 100%;
    height: 50px;
  }
  .field + .field {
    margin-left: 10px;
  }

  .field.half-width {
    width: 50%;
  }

  .field.quarter-width {
    width: calc(25% - 10px);
  }

  .baseline {
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: #bbb;
    transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  label {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 8px;
    color: #bbb;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform-origin: 0 50%;
    cursor: text;
    transition-property: color, transform;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    pointer-events: none;
  }

  .input {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding-bottom: 7px;
    color: #32325d;
    background-color: transparent;
  }

  .input::-webkit-input-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .input::-moz-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .input:-ms-input-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .input.StripeElement {
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: opacity;
  }

  .input.focused,
  .input:not(.empty) {
    opacity: 1;
  }

  .input.focused::-webkit-input-placeholder,
  .input:not(.empty)::-webkit-input-placeholder {
    color: #bbb;
  }

  .input.focused::-moz-placeholder,
  .input:not(.empty)::-moz-placeholder {
    color: #bbb;
  }

  .input.focused:-ms-input-placeholder,
  .input:not(.empty):-ms-input-placeholder {
    color: #bbb;
  }

  .input.focused + label,
  .input:not(.empty) + label {
    color: blue;
    transform: scale(0.8) translateY(-25px);
    cursor: default;
    @media screen and (max-width: 674px) {
      transform: scale(0.8) translateY(-32px);
    }
  }

  .input.focused + label {
    color: blue;
  }

  .input:not(.focused) + label {
    color: #bbb;
  }

  .input.invalid + label {
    color: #ffa27b;
  }

  .input.focused + label + .baseline {
    background-color: blue;
  }

  .input.focused.invalid + label + .baseline {
    background-color: #e25950;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: #e39f48;
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
  }

  .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  input {
    -webkit-animation: 1ms void-animation-out;
  }

  :disabled {
    opacity: 0.5;
  }

  // button {
  //   display: block;
  //   width: calc(100% - 30px);
  //   height: 40px;
  //   margin: 40px 15px 0;
  //   background-color: #24b47e;
  //   border-radius: 4px;
  //   color: #fff;
  //   text-transform: uppercase;
  //   font-weight: 600;
  //   cursor: pointer;
  // }

  .error {
    display: flex;
    align-items: center;
    & > * + * {
      margin-left: 5px;
    }
  }

  .error:not(.visible) {
    display: none;
  }

  .error svg {
    margin-top: 0 !important;
  }

  .error svg .base {
    fill: #e25950;
  }

  .error svg .glyph {
    fill: #fff;
  }

  .error .message {
    color: #e25950;
    font-size: 14px;
  }

  .success .icon .border {
    stroke: #abe9d2;
  }

  .success .icon .checkmark {
    stroke: #24b47e;
  }

  .success .title {
    color: #32325d;
    font-size: 16px !important;
  }

  .success .message {
    color: #8898aa;
    font-size: 13px !important;
  }

  .success .reset path {
    fill: #24b47e;
  }

  .powered-by-stripe {
    color: #bbb;
    display: flex;
    justify-content: flex-end;
    a {
      color: #bbb;
      text-decoration: none;
      &:hover {
        color: #4668f4;
      }
    }
  }
}