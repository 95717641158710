.CustomerList {
  
}

@media screen and (max-width: 674px) {
  .CustomerList {
    .Search {
      display: none;
    }
  }
}

@media screen and (max-width: 499px) {
  .CustomerList {
    a {
      display: block;
      grid-auto-columns: initial;
      span {
        display: block;
      }
      span + span {
        margin-top: 10px;
      }
    }
  } 
}