.ProductTable {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overflow-y: hidden;
  margin: 0 -15px;
  padding: 0 20px;
  @media (max-width: 767px) {
    padding: 0;
    overflow: hidden;
  }
  svg {
    transform: scale(0.9);
  }
  table {
    margin: auto;
    border-collapse: collapse;
    max-width: 1200px;
    th, td {
      padding: 6px 10px;
      @media (max-width: 767px) {
        padding: 4px 6px;
        font-size: 13px !important;
        max-width: 62px !important;
      }
    }
    th {
      font-weight: 400;
      width: 16%;
      @media (max-width: 767px) {
        &:first-child {
          // min-width: 40vw;
        }
      }
    }
    tbody tr:nth-child(even) {
      background: #f3f3f3;
    }
    .productname-row {
      th {
        font-size: 20px;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
    }
    .producttime-row {
      th {
        color: #999;
        padding-bottom: 20px;
      }
    }
    .checkmark-cell {
      text-align: center;
      @media (max-width: 767px) {
        svg {
          transform: scale(0.7);
        }
      }
    }
    .price-row {
      td {
        padding: 20px 0 0 0;
        text-align: center;
        font-size: 20px;
      }
    }
    .button-row {
      background: #f5f9fa !important;
      td {
        text-align: center;
        button {
          white-space: nowrap;
        }
      }
    }
  }  
}