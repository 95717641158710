.Confirm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0014;
  z-index: 1000;
  .content {
    position: fixed;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background: #fff;
    border-radius: 3px;
    animation: modal-slide-down 250ms ease-in-out;
    color: #555;
    .header {
      padding: 20px;
      font-weight: 600;
    }
    .body {
      padding: 5px 20px;
    }
    .footer {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button + button {
        margin-left: 25px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .Confirm {
    .content {
      width: calc(100vw - 30px);
      top: 25%;
      left: 15px;
      transform: none;
      animation: mobile-modal-slide-down 250ms ease-in-out;
    }
  } 
}

@media screen and (max-width: 674px) {
  .Confirm {
    .content {
      .footer {
        flex-direction: column;
        button {
          padding: 8px 20px;
        }
        button + button {
          margin: 25px 0 0 0;
        }       
      }
    }
  } 
}