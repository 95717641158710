.UserActivityForm {
  max-width: 600px;
  label {
    flex: 0 0 120px;
  }
  .Field.jsondata {
    label {
      align-self: flex-start;
    }
    .textarea {
      white-space: pre-wrap;
    }
  }
}
