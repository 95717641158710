.SkipWait {
  max-width: none !important;
  font-weight: 500;
  padding-top: 30vh;
  img.bg-cut {
    max-width: 700px;
  }
  h1 {
    color: #111;
    font-size: 28px;
    font-weight: 800;
  }
  h4 {
    color: #555;
    font-size: 23px;
    font-weight: 800;
  }
  p {
    color: #888;
    font-size: 18px;
    margin-top: 20px;
    max-width: 420px;
    line-height: 1.5;
  }
  .inner-content {
    h1 {
      text-align: left;
      max-width: none;
    }
  }
}

.SkipWait_Mobile {
  height: 435px;
  margin: 0 0 150px;
  color: #fff;
  position: relative;
  .skip-wait-content {
    text-align: center;
    padding: 60px 15px 20px;
  }
  h1 {
    font-size: 28px;
    font-weight: 600;
  }
  h4 {
    font-size: 19px;
    font-weight: 600;
    margin-top: 5px;
  }
  p {
    margin-top: 20px;
    font-weight: 500;
    line-height: 1.4;
  }
  img {
    position: absolute;
    bottom: -65px;
    left: 50%;
    transform: translateX(-50%);
    max-width: calc(100% - 30px);
  }
}

.skip-wait {
  position: relative;

  .bg-cut {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 100%, 55% 0);
  }

  .dirty-car {
    width: 43%;
    max-width: 700px;
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: baseline;
  }

  img {
    height: auto;
    width: 100%;
  }
}

.skip-wait .left {
  display: flex;
  align-items: center;

  .inner-content {
    padding-left: 3rem;

    h3,
    h4 {
      color: #151515;
      font-weight: 800;
      font-size: 30px;
      margin: 0;
      line-height: 30px;
    }

    h4 {
      font-size: 24px;
      font-weight: 600;
    }

    p {
      margin: 0.5rem 0;
      width: 50%;
    }
  }
}

.skip-wait .right {
  display: flex;
  align-items: center;
 
  .inner-content {
    margin: 0 5vw;
    width: 50%;
  }
}

.skipcar-text {
  width: 70% !important;
}
.skip-wait {
  @media screen and (max-width: 769px), print {
    .left {
      h3,
      h4,
      .inner-content p {
        padding: 0.25rem 0.5rem;
        width: 100%;
      }

      .inner-content {
        padding: 0 1.25rem;
      }
    }

    .right {
      .inner-content {
        padding: 0;
        width: 100%;
        padding: 0 1rem;
        margin-bottom: 2rem;
      }

      h3,
      p {
        text-align: center;
        padding: 0.25rem 0.5rem;
        width: 100%;
      }
    }

    .dirty-car {
      width: 80%;
      position: absolute;
      left: 50%;
      bottom: -60px;
      transform: translate(-50%, 0);
      display: flex;
      align-items: baseline;
    }
  }
}

@keyframes fadeAndScale {
  from {
    opacity: 0;
    transform: scale(0.9, 0.9);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}
