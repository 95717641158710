.AdminAccount {
  .Form {
    max-width: 500px;
  }
  .Field > label {
    flex: 0 0 120px;
  }
}

@media screen and (max-width: 674px) {
  .AdminAccount {
    .CollapsibleSection {
      margin-left: -20px;
      margin-right: -20px;
      border-left: none;
      border-right: none;
      .body {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}