.hero {
  margin-bottom: 2rem;
  position: relative;

  .cta {
    display: none;
  }

  a.signup {
    padding: 22px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: 100%;
    transition: all 150ms ease-in-out;
    &:hover, &:active, &:focus {
      color: #fff;
    }
    &.sticky {
      position: fixed;
      top: 55px;
      border-radius: 0;
      z-index: 20;
      border: none;
    }
  }

  .hero-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    .desktop-logo {
      width: 600px;
      display: flex;
      flex-direction: column;
      align-items: center;
      transform: translateY(60px);
      img {
        height: auto;
        width: 300px;
      }
      .signup-wrapper {
        margin-top: 40px;
        a {
          padding: 28px 50px;
        }
      }
      h1 {
        font-size: 40px;
        font-weight: 900;
        margin-top: 0px;
      }
      small {
        font-weight: 500;
        margin-top: 20px;
        display: block;
      }
    }
  }

  .banner-text {
    font-size: 20px;
    font-weight: 100;
    width: 70%;
    text-align: center;
  }

  .mobile-logo {
    display: none;
  }

  .arrow-one,
  .arrow-two,
  .arrow-three {
    display: none;
  }

  &.scrolled {
    nav.desktop,
    .mobile-nav {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 55px;
      background: #fff;
      box-shadow: 0 0 4px 4px #0000000a;
      z-index: 100;
      ul {
        position: absolute;
        right: 20px;
      }
      li {
        margin-right: 0;
      }
      li + li {
        margin-left: 30px;
      }
      ul,
      li,
      a {
        height: 100%;
        display: flex;
        align-items: center;
      }
      a.booknow {
        margin: 10px 0;
        height: calc(100% - 20px);
      }
    }
    nav.desktop {
      svg {
        margin-left: 5%;
      }
    }
    nav.mobile {
      z-index: 200;
    }
    .logo svg {
      left: 15px;
      top: 17px;
    }
    .hamburger svg {
      right: 20px;
      top: 17px;
    }
  }

  nav.desktop {
    position: absolute;
    top: 2rem;
    left: 5%;
    display: flex;
    align-items: center;

    svg {
      fill: #151515;
      height: auto;
      width: 100px;
    }

    ul {
      margin-left: 30px;
    }

    ul li {
      display: inline;
      margin-right: 1rem;

      a {
        color: #151515;

        &.booknow {
          background: #151515;
          padding: 0.6rem 1rem;
          color: #fff;
          border-radius: 3px;
          transition: all 0.3s ease;

          &:hover {
            background: #fff;
            border: 2px solid #151515;
            color: #151515;
          }
        }
      }
    }
  }

  nav.mobile,
  .logo,
  .hamburger {
    display: none;
  }

  .slanted {
    position: absolute;
    background: #333333;
    float: left;
    right: -60%;
    top: 0;
    bottom: 0;
    transform: skew(-30deg);
    -ms-transform: skew(-30deg); /* IE 9 */
    -webkit-transform: skew(-30deg); /* Safari and Chrome */
    overflow: hidden;
    height: 100%;
    width: 100%;

    video {
      height: 100vh;
      min-height: 100%;
      width: 100%;
      object-fit: fill;
      transform: skew(30deg);
      -ms-transform: skew(30deg); /* IE 9 */
      -webkit-transform: skew(30deg); /* Safari and Chrome */
      position: absolute;
      left: -25%;
      top: 0;
      bottom: 0;
    }
  }

  @media screen and (min-width: 770px) {
    .mobile-nav {
      display: none;
    }
  }
  @media screen and (max-width: 769px), print {
    .slanted {
      display: none;
    }

    nav.desktop {
      display: none;
    }

    nav.mobile {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100%;
      z-index: 20;
      display: none;

      .Button {
        position: absolute;
        top: 20px;
        right: 25px;
        img {
          width: 26px;
          height: 26px;
        }
      }

      &.active {
        display: block;
        animation: slide-up 200ms ease-in-out, fade-in 150ms ease-in-out;

        ul {
          display: block;
          height: 100%;
        }
      }

      ul {
        background: rgba(255, 255, 255, 0.99);
        height: 0;
        width: 100%;
        display: none;
        transition-property: all;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

        svg {
          height: auto;
          width: 50%;
          fill: #151515;
          margin: 5rem 0 2.5rem 0;
          padding-bottom: 2.5rem;
          border-bottom: 1px solid #cecece;
        }

        li {
          text-align: center;
          a {
            display: block;
            width: 100%;
            color: #151515;
            font-size: 20px;
            margin-bottom: 1.3rem;
          }
        }
      }
    }

    .logo,
    .hamburger {
      display: block;
    }

    .logo svg {
      fill: #151515;
      height: 16px;
      width: 16px;
      position: absolute;
      left: 2rem;
      top: 2rem;
      fill: #151515;
      height: auto;
      width: 75px;
      transition: all 100ms ease-in-out;
    }

    .hamburger svg {
      fill: #151515;
      height: 24px;
      width: 24px;
      position: absolute;
      right: 2rem;
      top: 2rem;
      transition: all 50ms ease-in-out;
    }

    .desktop-logo {
      display: none !important;
    }

    .mobile-logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: auto;
      animation: scale 2s ease-in-out 1;
      color: #222;
      img {
        height: 130px;
        width: auto;
        margin: -10px auto 0;
      }
      h1 {
        font-size: 29px;
        font-weight: 800;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        margin: 10px 0 0;
      }
      small {
        margin-top: 20px;
      }
    }

    .cta {
      position: absolute;
      width: 100%;
      bottom: -80px;
      display: flex;
      justify-content: center;
    }

    .arrow-one {
      display: block;
      position: absolute;
      bottom: -125px;
      left: 50%;
      height: 25px;
      width: 25px;
      transform: translate(-50%, 0);
      opacity: 0.5;

      svg {
        height: 25px;
        width: 25px;
      }
    }

    .arrow-two {
      display: block;
      position: absolute;
      bottom: -125px;
      left: 50%;
      height: 25px;
      width: 25px;
      transform: translate(-50%, 0);
      animation: arrow-2 1s ease-in-out infinite;

      svg {
        height: 25px;
        width: 25px;
        transform: translate(-50%, 0);
      }
    }

    .arrow-three {
      display: block;
      position: absolute;
      bottom: -125px;
      left: 50%;
      height: 25px;
      width: 25px;
      transform: translate(-50%, 0);
      animation: arrow-3 1s ease-in-out infinite;

      svg {
        height: 25px;
        width: 25px;
        transform: translate(-50%, 0);
      }
    }
  }
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes arrow-2 {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  75%,
  100% {
    transform: translateY(20px);
    opacity: 0.7;
  }
}

@keyframes arrow-3 {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  75%,
  100% {
    transform: translateY(40px);
    opacity: 1;
  }
}
