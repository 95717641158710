body {
  background: #fff;
  color: #555;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input:not([type='checkbox']):not([type='radio']),
select,
textarea {
  &:disabled {
    background: #f8f8f8;
    opacity: 1;
    box-shadow: none;
  }
}
input:not([type='checkbox']):not([type='radio']),
select,
button,
textarea {
  color: #555;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  display: inline-block;
  padding: 10px 12px;
  font-size: 16px;
  appearance: none;
  background: #0000;
  border-radius: 3px;
  border: none;
}
input:not([type='checkbox']):not([type='radio']),
select,
textarea {
  // box-shadow: 0 0 4px 2px #00000005;
  border: 1px solid #ddd;
  background: #fff;
  width: 100%;
  &:focus {
    // box-shadow: 0 0 11px -2px #0002;
    border-color: #4668f4aa;
    outline: none;
  }
}
select {
  background-image: url(./images/arrow-down-sm.svg);
  background-size: 12px 6px;
  background-position: right 12px top 50%;
  background-repeat: no-repeat;
}
button {
  border: 1px solid #ddd;
  user-select: none;
  color: #888;
  padding: 10px 25px;
  cursor: pointer;
  appearance: none;
  &:not(:disabled):hover {
    background: #f9f9f9;
  }
  &:not(:disabled):active,
  &:not(:disabled):focus {
    background: #f0f0f0;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
}
button.primary,
a.button.primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 25px;
  background: #4668f4;
  border: 1px solid #4668f4;
  color: #fff;
  text-decoration: none;
  &:not(:disabled):hover {
    background: darken(#4668f4, 5%);
    border-color: darken(#4668f4, 5%);
    text-decoration: none;
  }
  &:not(:disabled):active,
  &:not(:disabled):focus {
    background: darken(#4668f4, 10%);
    border-color: darken(#4668f4, 10%);
    text-decoration: none;
  }
}
button.secondary,
a.secondary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 25px;
  color: #4668f4;
  border: 1px solid #4668f4;
  text-decoration: none;
  transition: all 100ms ease-in-out;
  box-shadow: 0 0 6px 1px #0000;
  &:hover {
    text-decoration: none;
    box-shadow: 0 0 6px 1px #0002;
    color: darken(#4668f4, 5%);
    border-color: darken(#4668f4, 5%);
  }
  &:active,
  &:focus {
    text-decoration: none;
    color: darken(#4668f4, 10%);
    border-color: darken(#4668f4, 10%);
  }
}
.divider {
  background-color: #4668f4;
  height: 3px;
  margin: auto;
}
.gray-divider {
  height: 1px;
  width: 100%;
  background-color: #eee;
}
.header {
  padding: 20px 0;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  & > * + button {
    margin-left: 20px;
  }
}
.select-or-add {
  max-width: 500px;
  margin: auto;
  border: 1px solid #4668f433;
  border-radius: 3px;
  padding: 5px 0;
  h3 {
    margin: 10px 15px;
    padding: 0;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      margin: 0 !important;
    }
  }
  .item {
    padding: 15px;
    cursor: pointer;
    &:hover:not(.selected) {
      background: #4668f406;
      color: #000;
    }
    &.selected {
      background: #4668f409;
      color: #4668f4;
    }
  }
  .item + .item {
    border-top: 1px solid #eee;
  }
}
  
.select-or-add-portal {
  padding: 20px;
  h1 {
    font-size: 25px;
    margin: 0 0 30px 0;
  }
}

.list {
  &.list-primary {
    margin-left: -20px;
    margin-right: -20px;
    a {
      padding: 20px;
      display: grid;
      grid-template-columns: repeat(2, 300px);
      align-items: center;
      background-color: #fff;
      &:hover {
        color: #000;
        background: #4668f406;
      }
    }
    a + a {
      border-top: 1px solid #f0f0f0;
    }
  }
}
.filter-bar {
  max-width: 500px;
  margin-bottom: 20px;
  label {
    flex: 0 0 120px;
  }
}
.caps {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
}
.all-caps {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #999;
  margin: 0;
  padding: 0;
}
.color-1 {
  color: rgb(80, 189, 163);
}
.color-2 {
  color: rgb(24, 148, 162);
}
.color-3 {
  color: rgb(68, 104, 241);
}
.color-4 {
  color: rgb(10, 14, 40);
}
.bg-color-1 {
  color: #fff;
  background-color: rgb(80, 189, 163);
}
button.bg-color-1:not(:disabled) {
  &:hover {
    background-color: darken(rgb(80, 189, 163), 5%);
  }
  &:active, &:focus {
    background-color: darken(rgb(80, 189, 163), 10%);
  }
}
.bg-color-2 {
  color: #fff;
  background-color: rgb(24, 148, 162);
}
button.bg-color-2:not(:disabled) {
  &:hover {
    background-color: darken(rgb(24, 148, 162), 5%);
  }
  &:active, &:focus {
    background-color: darken(rgb(24, 148, 162), 10%);
  }
}
.bg-color-3 {
  color: #fff;
  background-color: rgb(68, 104, 241);
}
button.bg-color-3:not(:disabled) {
  &:hover {
    background-color: darken(rgb(68, 104, 241), 5%);
  }
  &:active, &:focus {
    background-color: darken(rgb(68, 104, 241), 10%);
  }
}
.bg-color-4 {
  color: #eee;
  background-color: rgb(10, 14, 40);
}
button.bg-color-4:not(:disabled) {
  &:hover {
    background-color: darken(rgb(10, 14, 40), 5%);
  }
  &:active, &:focus {
    background-color: darken(rgb(10, 14, 40), 10%);
  }
}
.color-divider {
  width: 50%;
  height: 3px;
  background: #39a8eb;
  background: linear-gradient(128deg, #35d88e 0%, #39a8eb 100%);
  margin: 20px auto;
}
.basic-card {
  padding: 30px 30px 30px 15px;
  box-shadow: 0 0 6px 1px #0002;
  background: #fff;
  max-width: 400px;
  margin: auto;
  display: flex;
  align-items: flex-start;
  font-size: 15px;
  font-weight: 500;
  img {
    margin-right: 10px;
  }
}
@media screen and (max-width: 499px) {
  .list {
    &.list-primary {
      a {
        padding: 15px;
        display: block;
        grid-auto-columns: initial;
        span {
          display: block;
        }
        span + span {
          margin-top: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 674px) {
  html,
  body {
    font-size: 18px;
  }
  input:not([type='checkbox']):not([type='radio']),
  select,
  button,
  textarea {
    font-size: 18px;
  }
  .header {
    font-size: 22px;
  }
  .select-or-add {
    margin: 10px;
  }
}
