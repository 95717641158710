.ProductInfo {
  &:hover, &:active, &:focus {
    background: #0000 !important;
  }
  img {
    width: 20px;
    height: 20px;
  }
}

.ProductInfo_Dialog {
  .content .header {
    font-size: 22px;
    padding-bottom: 12px;
  }
  .body > p:first-child {
    margin-top: 0;
  }
  small {
    font-size: 16px;
    color: #aaa;
  }
  ul {
    padding: 0 0 0 10px;
    max-width: 80%;
    li {
      list-style: none;
      line-height: 1.6;
    }
    li:before {
        content: '';
        display: inline-block;
        height: 14px;
        width: 16px;
        margin-right: 10px;
        background-size: 16px;
        background-image: url(../../../../assets/carwash-icon-shine.svg);
        background-repeat: no-repeat;
    }
  } 
  .ProductTable {
    padding-bottom: 20px;
    margin: 0 -20px;
    table {
      margin: 0;
      width: calc(100% + 40px);
      td {
        padding: 4px 15px;
        height: 50px;
        font-size: 16px !important;
        &:first-child {
          width: 60%;
        }
        &.checkmark-cell {
          width: 40%;
        }
      }
      thead,
      .price-row,
      .button-row {
        display: none;
      }
    }
  }
  .footer {
    display: none !important;
  }
}