.Contact {
  .content {
    display: grid;
    grid-column-gap: 50px;
    grid-row-gap: 75px;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
  h2 {
    letter-spacing: 1px;
    margin-bottom: 60px;
  }
  .Field {
    display: block;
    label {
      display: block;
      margin-bottom: 7px;
    }
    textarea {
      min-height: 150px;
    }
  }
  .contact-info {
    .bold {
      font-weight: 600;
      margin: 30px 0;
      letter-spacing: 1px;
      color: #888;
    }
    .bold:first-child {
      margin-top: 0;
    }
    a {
      color: #555;
      text-decoration: none;
    }
  }
  .response {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
