.ReservationForm {
  margin: 20px;
  max-width: 500px;
  label {
    flex: 0 0 120px;
  }
  .header {
    margin: 0 0 20px0;
  }
  .price-info {
    margin-top: 25px;
    font-size: 13px;
  }
  .unavialable-message {
    display: inline-block;
    margin-left: 20px;
    font-size: 13px;
    color: #888;
  }
}

.ReservationFormWrapper {
  .header {
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
  }
}