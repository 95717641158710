.Tooltip {
  position: fixed;
  z-index: 100;
  svg {
    position: absolute;
    width: 10px;
    height: 5px;
    top: 0;
    left: -5px;
  }
  path {
    fill: #555;
  }
  .content {
    transform: translate(-50%, 5px);
    background: #555;
    color: #eee;
    padding: 6px 10px;
    border-radius: 3px;
    font-size: 14px;
  }
}
