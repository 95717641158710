.Dashboard {
  & > .sidebar {
    position: fixed;
    top: 55px;
    left: 0;
    bottom: 0;
    width: 250px;
    border-right: 1px solid #eee;
    a {
      display: block;
      padding: 10px 25px;
      text-decoration: none;
      color: #555;
      &.car {
        img {
          transform: scale(1.3) translateY(1px);
        }
      }
      &.active {
        background: #4668f409;
        color: #4668f4;
      }
      .subtitle {
        margin-left: 5px;
        color: #ccc;
        font-size: 90%;
      }
    }
    img {
      width: 16px;
      height: 16px;
      margin-right: 15px;
    }
  }
  & > .content {
    margin-left: 250px;
    padding: 20px;
    position: relative;
    & > * {
      animation: slide-up 350ms ease-in-out, fade-in 250ms ease-in-out;
    }
  }
}

@media screen and (max-width: 674px) {
  .Dashboard {
    & > .sidebar {
      top: auto;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 45px;
      display: flex;
      justify-content: space-between;
      border-right: none;
      border-top: 1px solid #eee;
      background: #fff;
      z-index: 10;
      overflow-x: auto;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      max-width: 100%;
      a {
        flex: 1 1 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        min-width: 22vw;
        white-space: nowrap;
        img {
          margin-top: 5px;
          margin-right: 0;
          width: 20px;
          height: 20px;
        }
        span {
          font-size: 10px;
          font-weight: 600;
          text-align: center;
          margin-top: 3px;
        }
        span.subtitle {
          display: none;
        }
      }
    }
    & > .content {
      margin-bottom: 40px;
      margin-left: 0;
    }
  }
}
