.Cart {
  padding: 20px 20px 50px;
  background: #fff;
  .selection {
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
  }
  .addons {
    padding-top: 15px;
  }
  .addon {
    padding-bottom: 15px;
    border-bottom: 1px solid #f4f4f4;
  }
  .addon + .addon {
    margin-top: 15px;
  }
  .totals {
    padding-top: 15px;
  }
  .total {
    font-weight: 600;
  }
  & > .packagedeal,
  & > .selection,
  & > .addons {
    .footer {
      margin-top: 15px;
      margin-bottom: 0;
      text-align: left;
      button {
        font-size: 14px;
        color: #e55;
        margin: 0;
        &:hover, &:active, &:focus {
          background: #ffff;
        }
      }
    }
  }
  h3 {
    margin: 0 0 20px 0;
    padding: 0;
    font-size: 22px;
  }
  h4 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #999;
    margin: 0 0 15px 0;
    padding: 0;
  }
  .discount-section {
    padding: 15px 0;
    border-bottom: 1px solid #eee;
    h4 {
      margin-bottom: 7px;
    }
  }
  .discount-input {
    display: flex;
    align-items: center;
    .Input:not(.has-value) label {
      top: 28px;
      left: 13px;
    }
    .Input.has-value label {
      top: 2px;
    }
    input, button {
      padding-top: 7px;
      padding-bottom: 7px;
    }
    input {
      border-radius: 3px 0 0 3px !important;
      border-right-width: 0;
      color: #555 !important;
      border: 1px solid #ddd !important;
      &:focus {
        border-color: #4668f4aa !important;
      }
    }
    button {
      margin-top: 20px !important;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border: 1px solid #4668f4 !important;
      padding-left: 17px;
      padding-right: 17px;
    }
  }
  &.inline {
    max-width: 500px;
    margin: auto;
    .footer {
      text-align: left;
    }
  }
}

@media screen and (min-width: 1000px) {
  .Cart:not(.inline) {
    position: fixed;
    top: 55px;
    right: 0;
    bottom: 0;
    border-left: 1px solid #eee;
    width: 300px;
    overflow-y: auto;
    &.show-covid-bar {
      top: 200px;
    }
  }

}

@media screen and (max-width: 999px) {
  .Cart {
    padding: 0;
    max-width: 500px;
    margin: 0 auto;
  }
}

.package-deal-products {
  color: #aaa;
}
