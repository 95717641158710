.Calendar {
  height: 400px;
  .BasicEventInfo {
    display: flex;
    align-items: center;
    .time {
      color: #e5e5e5;
      font-size: 11px;
      margin-right: 5px;
    }
    .title {
      font-size: 13px;
    }
  }
}
.Screen .Calendar {
  height: auto;
  .form-wrapper {
    .header {
      padding: 20px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}
