.BubbleHeader {
  .blue-bubbles {
    background: rgb(0, 125, 214) url(../../assets/bubbles.png) top center / cover no-repeat;
    margin: -24px -20px 20px;
    padding: 7vh 15px 280px;
    position: relative;
    min-height: 400px;
  }
  .white-bubbles {
    background: url(../../assets/bubbles-white.png) 100% center / cover no-repeat;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 20vw;
    width: 100%;
  } 
  .bubble-text {
    color: #fff;
    background-color: #007dd488;
    border-radius: 4px;
    font-size: 25px;
    max-width: 500px;
    margin: 50px auto 0;
    text-align: center;
    font-weight: 600;
    position: relative;
    z-index: 1;
  }
  @media screen and (max-width: 674px) {
    .blue-bubbles { 
      min-height: 185px;
      padding: 20px 15px 60px;
    }  
  }
}