.Screen {
  animation: grow 250ms ease-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fdfdfd;
  z-index: 1000;
  transition: all 250ms;
  &.closing {
    opacity: 0;
    transform: scale(0.9);
  }
}

.ScreenWithClose {
  display: grid;
  grid-template-columns: 50px 1fr;
  & > .left {
    padding: 20px 10px 20px 20px;
    button img {
      width: 20px;
      height: 20px;
    }
    & + * {
      overflow-y: auto;
    }
  }
}

@media screen and (max-width: 674px) {
  .ScreenWithClose {
    display: block;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    & > .left {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 55px;
      padding: 0 20px;
      & + * {
        overflow-y: initial;
      }
    }
  }
}