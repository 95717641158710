.Button {
  padding: 0;
  margin: 0;
  border: none;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
  label {
    cursor: pointer;
  }
  img + span {
    margin-left: 10px;
  }
  &.danger {
    background: red;
    color: #fff;
    padding: 8px 20px;
    &:not(:disabled):hover {
      background: darken($color: red, $amount: 10%);
    }
    &:not(:disabled):active, &:not(:disabled):focus {
      background: darken($color: red, $amount: 20%);
    }
  }
}