.Services {
  position: relative;
  z-index: 2;
  margin-top: 10rem;

  h1,
  h2 {
    color: #404549;
    font-size: 27px;
    font-weight: 700;
    text-align: center;
    line-height: 35px;
    margin: 0;
  }

  h2 {
    font-size: 18px;
    font-weight: 500;
    margin: 30px 0 20px;
  }

  p {
    color: #616567;
    font-size: 16px;
    width: 65%;
    margin: 0 auto;
    padding: 1rem 0;
    text-align: center;
  }

  h3 {
    color: #4b4d62;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    line-height: 35px;
    margin: 0;
  }
}

.display-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-side-one,
.feature-side-two {
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-side-one {
  div.first {
    margin-right: 1rem;
  }

  div.second {
    margin-right: 10rem;
  }
}

.feature-side-two {
  div.first {
    padding-left: 1rem;
  }

  div.second {
    padding-left: 9rem;
  }
}

.feature-side-one,
.feature-side-two {
  flex-flow: row wrap;

  div.first {
    margin-right: 1rem;
  }

  div.second {
    margin-right: 10rem;
  }

  div,
  h3 {
    flex: 0 0 100%;
    text-align: right;
    color: #151515;
  }
  div {
    margin-bottom: 3rem;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    -webkit-box-shadow: 5px 10px 15px -12px rgba(57, 102, 215, 0.75);
    -moz-box-shadow: 5px 10px 15px -12px rgba(57, 102, 215, 0.75);
    box-shadow: 5px 10px 15px -12px rgba(57, 102, 215, 0.75);
    border: 1px #3966d7 dashed;
    padding: 10px;
    margin-left: auto;
    margin-bottom: 1rem;
  }

  svg {
    fill: #3966d7;
    height: 25px;
    width: 25px;
  }
}

.feature-side-two {
  div,
  h3 {
    text-align: left;
  }

  span {
    margin-right: auto;
    margin-left: 0;
  }
}

// Mobile styles {

.Services {
  @media screen and (max-width: 769px), print {
    margin: 5rem 0;

    .car-graphic {
      text-align: center;
    }

    img {
      height: auto;
      width: 80%;
    }

    h1,
    h2,
    p {
      padding: 0 1rem;
      margin-bottom: 1rem;
    }

    p {
      width: 100%;
    }

    .feature-side-one .first,
    .feature-side-one .second,
    .feature-side-two .first,
    .feature-side-two .second {
      padding: 0;
      margin: 0;

      div,
      h3 {
        text-align: center;
        margin: 0;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}
