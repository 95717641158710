.MobileFriendly_Mobile {
  height: 460px;
  padding: 90px 0 0;
  position: relative;
  img.car {
    transform: translateX(-40%);
  }
  .phone {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 260px;
    height: 423px;
    background-size: contain;
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  img.logo {
    margin-top: 130px;
    width: 70%;
  }
  a {
    margin-top: 80px;
    width: 70%;
    background: rgb(62, 94, 243);
    color: #fff;
    padding: 12px;
    text-align: center;
    border-radius: 3px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
  }
}

.MobileFriendly {
  max-width: 1050px;
  margin: auto;
  padding: 150px 20px 120px;
  display: flex;
  align-items: center;
  position: relative;
  h4 {
    flex: 1;
    color: #151515;
    font-size: 24px;
    font-weight: 600;
    margin: 0 20px 0 0;
    line-height: 30px;
  }
  .car-img {
    flex: 2;
    img {
      width: 400px;
    }
  }
  .mobile-img {
    position: absolute;
    bottom: 0;
    right: 20px;
    width: 320px;
    height: 468px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .logo-banner-img {
    width: 60% !important;
  }
  .signup-wrapper {
    margin-top: 50px;
  }
  #book-now-9 {
    height: 45px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 15px !important;
    border-color: #0000 !important;
    &:hover, &:active, &:focus {
      color: #fff !important;
      border-color: #0000 !important;
    }
  }
}
