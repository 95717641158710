.Process {
  background: #f3f3f3;
  margin-top: 40vh;
  padding: 5rem 0;

  .extra-padding {
    padding: 0 5rem;
  }

  .simple-process {
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    margin: 3rem 0 1.5rem 0;
  }

  .column.step {
    text-align: center;

    p {
      font-weight: 500;
      font-size: 18px;
    }

    span {
      display: inline-block;
      text-align: center;
      width: 100%;
      font-size: 100px;
      line-height: 1em;
      font-weight: 700;
      color: #edebeb;
      background: transparent;
      background: -moz-linear-gradient(
        top,
        #edebeb 0%,
        #f7f6f6 74%,
        rgba(255, 255, 255, 0.87) 100%
      );
      background: -webkit-linear-gradient(
        top,
        #edebeb 0%,
        #f7f6f6 74%,
        rgba(255, 255, 255, 0.87) 100%
      );
      background-clip: border-box;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
      text-fill-color: transparent;
      position: relative;
      bottom: -14px;
      z-index: 1;
    }
  }

  p {
    color: #616567;
  }

  @media screen and (min-width: 769px) {
    .extra-style {
      height: 160px;
      padding: 0;
    }

    .column.step {
      p {
        margin-top: 20px;
      }
    }
  }
  
  @media screen and (max-width: 768px), print {
    padding: 2rem 0 3rem;
    margin-top: 0;

    .container {
      padding: 1rem 0 0 0;
    }

    .extra-padding {
      padding: 20px 0 0;
    }

    .simple-process {
      line-height: auto;
      margin: 20px 0 0;
      font-size: 28px;
    }

    .progressbar {
      display: flex;
      flex-direction: column;
      align-items: center;
      li {
        width: auto;
        &.active {
          font-size: 40px;
        }
        p {
          font-size: 22px;
          max-width: 280px;
        }
      }
      li:after {
        height: 0;
      }
      li + li {
        margin-top: 30px;
      }
    }

    .car,
    .pet,
    .suv {
      .inner-content {
        width: 60%;
        display: block;
        position: relative;
        z-index: 10;
      }

      .blackout {
        display: block;
        background: rgba(0, 0, 0, 0.3);
      }

      &:hover {
        .inner-content,
        .blackout {
          display: block;
          animation: none;
        }
      }
    }
  }
  
}

.progressbar {
  counter-reset: step;
}
.progressbar li {
  list-style: none;
  display: inline-block;
  width: 25%;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 70px;
  height: 70px;
  line-height : 70px;
  border: 1px solid #ddd;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  background-color: #fff;
}
.progressbar li:after {
  content: "";
  position: absolute;
  width: 74%;
  height: 1px;
  background-color: #ddd;
  top: 35px;
  left: -37%;
  z-index : 0;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: #3E5EF3;
  font-size: 30px;
  font-weight: 600;
}
.progressbar li.active:before {
  border-color: black;
}
.progressbar li.active + li:after {
  margin-bottom: -20px;
  background-color: #7D7D7D;
}
