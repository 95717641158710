.BackButton {
  border: none;
  background: none;
  margin: 0;
  padding: 5px 15px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  label {
    cursor: pointer;
    margin-left: 5px;
    transform: translateY(-1px);
  }
  img {
    width: 15px;
    height: 30px;
  }
}