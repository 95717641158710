.BookingPage {
  background: #fff;
  margin: -20px;
  padding-bottom: 150px;

  .BubbleHeader .blue-bubbles {
    margin: 0;
  }
  .DiscreteProgessBar {
    margin: 30px auto 50px auto;
    max-width: 400px;
  }
  .PaymentForm {
    max-width: 400px;
    margin: auto;
  }
  .container {
    transition: transform 200ms, opacity 150ms;
    transform: translateX(0);
    opacity: 1;
    &.hiding {
      transform: translateX(-500px);
      opacity: 0;
    }
    button:not(.BackButton) {
      margin-top: 25px;
    }
  }

  .calendar-wrapper {
    max-width: 800px;
    margin: auto;
  }
  .rbc-btn-group {
    button {
      margin: 0 !important;
    }
    & > button:nth-child(2) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-right: none;
    }
  }

  .confirm-step {
    max-width: 500px;
    margin: auto;
    border: 1px solid #ddd;
    border-radius: 3px;
  }

  .done-message {
    font-size: 18px;
    line-height: 1.7;
    max-width: 600px;
    margin: auto;
    padding: 15px;
  }

  form {
    max-width: 400px;
    margin: auto;
  }
  .Input textarea {
    min-height: 120px;
  }
  h1 {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    position: relative;
    text-align: center;
    .BackButton {
      position: absolute;
      top: 5px;
      left: 0;
      label {
        margin-left: 0;
      }
      img {
        width: 12px;
        height: 22px;
      }
    }
  }
  .product-header {
    margin-top: 60px;
    font-size: 18px;
  }
  .footer {
    text-align: center;
    button + button {
      margin-left: 15px;
    }
    button.secondary {
      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
      }
    }
  }
  .plans,
  .add-ons {
    text-align: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    row-gap: 20px;
    column-gap: 20px;
  }
  .plan {
    border-radius: 3px;
    transform: translateY(0);
    transition: all 100ms ease-in-out;
    padding: 20px;
    cursor: pointer;
    background: #fff;
    box-shadow: 0 0 20px 10px #00000004;
    &:hover {
      box-shadow: 0 0 20px 10px #0001;
      transform: translateY(-1px);
    }
    &.active {
      background: #dbf8ff;
    }
    .name {
      font-weight: 600;
      margin-top: 15px;
    }
    .amount {
      margin-top: 15px;
      color: #777;
    }
  }
  .StripeElement {
    margin-top: 40px;
  }
  .image-wrapper {
    .image {
      width: 70px;
      height: 70px;
      border-radius: 35px;
      background-size: 514px 556px;
      background-repeat: no-repeat;
      margin: auto;
    }
  }
  .toggle {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: #555;
    }
  }
  .child-plans {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 300px));
    grid-gap: 30px;
    justify-content: center;
    @media screen and (min-width: 900px) {
      margin: 0 -50px;
    }
    @media screen and (min-width: 1000px) {
      margin: 0 -100px;
    }
    @media screen and (min-width: 1200px) {
      margin: 0 -200px;
    }
    @media screen and (min-width: 1400px) {
      margin: 0 -400px;
    }
  }
  .child-plan {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease-in-out;
    padding: 2rem;
    display: grid;
    grid-template-rows: 31px 130px 35px 43px;
    grid-row-gap: 30px;
    position: relative;
    overflow: hidden;
    h2 {
      margin: 0;
      padding: 0;
      text-align: center;
    }
    ul {
      min-height: 150px;
      margin: 0;
      padding: 0 0 0 20px;
    }
    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    }
    p {
      margin: 0;
    }
    .price {
      font-size: 30px;
      font-weight: 700;
      line-height: 30px;
      text-align: center;
      &:before {
        content: '$';
        font-size: 14px;
        vertical-align: text-top;
        font-weight: 400;
        color: #818283;
      }
    }
    &:not(.one-time) {
      .price {
        &:after {
          color: #818283;
          content: ' / month';
          font-size: 14px;
          vertical-align: text-top;
          font-weight: 400;
        }
      }
    }
    button {
      display: block;
      width: 100%;
      margin-top: 0;
      transform: translateY(-10px);
    }
  }
  .step-description {
    color: #888;
    text-align: center;
    margin-bottom: 0;
  }
  sup {
    font-weight: 400;
    color: #818283;
    font-size: 14px;
    position: relative;
    top: 2px;
  }
  sub {
    position: absolute;
    padding: 3px 40px;
    top: 23px;
    right: -34px;
    font-size: 13px;
    font-weight: 600;
    transform: rotate(45deg);
    background: #4668f4;
    color: #fff;
  }
  .disclaimer {
    margin-top: 60px;
    font-size: 13px;
    color: #c5c5c5;
  }
  .ProductGrid {
    margin-top: 50px;
  }
  .car-info, .account, .payment {
    padding-top: 40px;
    padding-bottom: 40px;
    max-width: 800px;
    margin: auto;
  }
  input:not([type='checkbox']):not([type='radio']), select, textarea {
    background: none;
    border: none;
    border-bottom: 1px solid #bbb;
    border-radius: 0;
    color: #eee;
    &:focus {
      border-color: #4668f4;
    }
  }
  .Input.has-value input:focus + label, .Input.has-value textarea:focus + label {
    color: #4668f4;
  }
  textarea {
    border: 1px solid #bbb;
  }
  .StripeForm {
    background: none;
  }
  .communication-info {
    margin-top: 40px;
    color: #c5c5c5;
  }
  .appointment {
    .detail-form {
      max-width: 800px;
    }
    .footer {
      padding: 50px 0 30px;
    }
  }
  .Calendar {
    height: 700px;
    margin-top: 30px;
    button {
      margin-top: 0;
    }
    .rbc-toolbar {
      position: relative;
      justify-content: flex-start;
      .rbc-btn-group:first-child button:first-child {
        display: none;
      }
      .rbc-toolbar-label {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      button:active, 
      button.rbc-active {
        box-shadow: none;
        background-color: #0000;
      }
      button:focus {
        background-color: #0000;
      }
    }
  }
  .calendar-placeholder {
    padding: 30px 20px 20px;
    div {
      animation: placeholder 2s ease-in-out infinite;
    }
    .div-1 {
      height: 33px;
      margin-bottom: 10px;
    }
    .div-2 {
      height: 16px;
      margin-bottom: 6px;
    }
    .div-3 {
      height: 678px;
    }
  }
  .Cart {
    padding-top: 20px;
    padding-bottom: 40px;
    h4 {
      margin-bottom: 10px;
    }
  }
  .confirm-car {
    max-width: 500px;
    margin: auto;
    background: #fff;
    padding: 20px 20px 0;
    .gridfields > * > span:first-child {
      color: #999;
    }
    .all-caps {
      align-items: center;
    }
    .gray-divider {
      margin-top: 20px;
    }
    .Button {
      margin-top: 0;
      color: #4668f4;
      &:hover, &:active, &:focus {
        background: #0000;
      }
    }
    @media screen and (max-width: 999px) {
      .Button {
        font-size: 16px;
      }
    }
  }
  .summary {
    padding: 8px 30px;
    background: #fffe;
    box-shadow: 0 0 4px 4px rgba(0,0,0,0.04);
    position: fixed;
    top: 55px;
    left: 0;
    right: 0;
    z-index: 10;
    @media screen and (min-width: 675px) {
      div {
        max-width: 200px;
        margin: auto;
      }
    }
  }
}

@media screen and (max-width: 999px) {
  .BookingPage {
    .Calendar .rbc-toolbar {
      flex-direction: row;
      align-items: center;
      position: static;
      justify-content: space-between;
      .rbc-btn-group:first-child {
        order: 0;
        margin-top: 0;
      }
      .rbc-toolbar-label {
        position: static;
        transform: none;;
      }
    }
    .appointment .Cart {
      padding-left: 15px;
      padding-right: 15px;
    }
    .detail-form form {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

@media screen and (max-width: 767px) {
  .BookingPage {
    h1 {
      font-size: 25px;
    }
    .step-description {
      font-size: 16px;
    }
    .container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

@media screen and (max-width: 499px) {
  .BookingPage {
    .DiscreteProgessBar {
      margin-top: 5px;
      margin-bottom: 20px;
    }
    h1 {
      margin-bottom: 10px;
    }
    .ProductGrid {
      margin-top: 30px;
    }
    .car-info, .account, .payment {
      margin-left: -20px;
      margin-right: -20px;
      padding: 40px 15px 0;
    }
    .confirm-car {
      padding-left: 15px;
      padding-right: 15px;
    }
    .Cart {
      padding-left: 15px;
      padding-right: 15px;
      color: #555;
    }
    .footer {
      margin-bottom: 60px;
    }
    .disclaimer {
      margin-bottom: 60px;
    }
    .SelectOrAddAddress, .SelectOrAddCar {
      // margin-left: -20px;
      // margin-right: -20px;
    }
  }
}

@media screen and (max-width: 390px) {
  .BookingPage {
    .DiscreteProgessBar {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}