.ProductForm {
  max-width: 500px;
  label {
    flex: 0 0 120px;
  }
  .header {
    margin: 0 0 20px0;
  }
  textarea {
    min-height: 200px;
  }
  .add_ons {
    & > label {
      align-self: flex-start;
    }
  }
}
