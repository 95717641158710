.PaymentPage {
  padding: 60px 20px;  
  max-width: 600px;
  margin: auto;
  &.loading {
    padding-top: 80px;
    text-align: center;
  }
  .options {
    display: flex;
    button {
      flex: 1 1 20%;
      text-align: center;
      justify-content: center;
      padding: 10px;

      span + span {
        color: #aaa;
        font-size: 80%;
        margin-left: 10px;
      }

      &.primary {
        text-align: center;
        justify-content: center;
        border-color: #4668f4;
        span + span {
          color: #ddd;
        }
      }
      &:nth-child(1) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:nth-child(2),
      &:nth-child(3) {
        border-left: none;
        border-radius: 0;
      }
      &:nth-child(4) {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  .custom-amount {
    margin-top: 30px;
    label {
      flex: 0 0 130px;
    }
    .Input {
      flex: 0 0 100px;
    }
  }
  .pay {
    margin-top: 40px;
    button {
      padding: 20px;
      width: 100%;
      justify-content: center;
      &:disabled {
        opacity: 0.7;
        cursor: default;
      }
    }
  }
  .done {
    margin-top: 30px;
    a {
      border-radius: 3px;
    }
  }
  textarea {
    min-height: 120px;
  }
  .feedback-textbox-label {
    margin: 100px 0 10px 0;
  }
  .feedback-button {
    margin-top: 20px;
  }
  @media screen and (max-width: 940px) {
    button, a.button {
      display: flex;
      justify-content: center;
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}
