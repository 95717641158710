.Account {
  max-width: 1000px;
  margin: auto;  
  .StripeElement {
    max-width: 500px;
  }
  .CollapsibleSection {
    margin-top: 20px;
    margin-left: -20px;
    margin-right: -20px;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.02);
    border: none;
    background-color: #fff;
  }
  .CollapsibleSection + .CollapsibleSection {
    margin-top: 30px;
  }
  .Form {
    max-width: 500px;
  }
  .Field > label {
    flex: 0 0 120px;
  }
  & > * {
    animation: slide-up 350ms ease-in-out, fade-in 250ms ease-in-out;
  }
  .account-content {
    .links {
      margin: 0 -20px;
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        background-color: #fff;
        color: #555;
        text-decoration: none;
        font-weight: 600;
        img {
          height: 6px;
          transform: rotate(-90deg);
        }
      }
      a + a {
        border-top: 1px solid #eee;
      }
    }
  }
  .logout {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.AccountCreated {
}
