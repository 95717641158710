.Input {
  padding-top: 20px;
  position: relative;
  &:not(.checkbox) label {
    position: absolute;
    top: 31px;
    left: 13px;
    font-size: 16px;
    color: #bbb;
    transition: all 75ms ease-in-out;
    pointer-events: none;
  }
  &.currency label {
    left: 24px;
  }
  &:not(.checkbox).has-value {
    input:focus + label,
    textarea:focus + label {
      color: blue;
    }
    label {
      top: 0px;
      left: 0px;
      transform: translate(0);
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
  &.currency {
    input {
      padding-left: 25px;
    }
    .dollarsign {
      position: absolute;
      transform: translate(10px, 11px);
      font-size: 16px;
      color: #999;
    }
  }
  &.percent {
    max-width: 80px;
    input {
      padding-right: 25px;
    }
    .percentsign {
      position: absolute;
      top: 50%;
      right: 11px;
      transform: translateY(-50%);
      font-size: 16px;
      color: #999;
    }
  }
  input[type="text"], 
  input[type="email"], 
  input[type="password"], 
  input[type="num"], 
  input[type="number"], 
  input[type="tel"], 
  input[type="currency"], 
  input[type="percent"], 
  textarea {
    width: 100%;
    &[type='file'] {
      background: #0000;
      box-shadow: none;
      width: auto;
      transform: translateX(125px);
    }
  }
  textarea {
    min-height: 70px;
  }
  .toggle {
    color: #4668f4;
    cursor: pointer;
    text-decoration: none !important;
    font-size: 12px;
    position: absolute;
    right: 13px;
    bottom: 12px;
  }
  & + .Input {
    margin-top: 15px;
  }
}

@media screen and (max-width: 674px) {
  .Input {
    label {
      top: 30px;
      font-size: 18px;
    }
    .toggle {
      bottom: 14px;
    }
  }
}

.Input_Portal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  ul {
    z-index: 1050;
    position: absolute;
    background: #fff;
    border-radius: 3px;
    padding: 5px 0;
    list-style: none;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    box-shadow: 0 0 4px 4px #0001;
  }
  li {
    cursor: pointer;
    padding: 5px 12px;
    &:hover {
      background: #f8f8f8;
    }
  }
}