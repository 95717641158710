.CovidBar {
  position: fixed;
  top: 55px;
  left: 0;
  height: 145px;
  width: 100%;
  background-color: #3445B9;
  z-index: 100;
  padding: 5px;
  padding-right: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 28px;
  img.stamp {
    height: 100px;
    margin-right: 10px;
  }
  div {
    text-align: center;
  } 
  a.button.primary {
    text-decoration: none;
    padding: 10px 15px;
    background: none;
    border: 1px solid #fff;
    border-radius: 3px;
    margin-top: 5px;
    &:hover, &:active, &:focus {
      background: none;
      border: 1px solid #fff;
    }
  }
  .close {
    position: absolute;
    top: 60px;
    right: 20px;
    &:hover {
      background: none;
    }
  } 
  @media screen and (max-width: 940px) {
    height: 80px;
    font-weight: 600;
    font-size: 16px;
    img.stamp {
      height: 78px;
    }
    a.button.primary {
      margin: 0 0 0 15px;
    }
    .close {
      top: 31px;
    }
    a {
      color: #fff;
      text-decoration: none;
    }
  }
  @media screen and (max-width: 435px) {
    justify-content: space-between;
    padding-right: 10px;
    .imglink {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img.stamp {
      height: 75px;
    }
    a.button.primary {
      margin-left: 2px;
    }
    .close {
      display: none;
    }
  } 
}