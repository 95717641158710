.CorporateAccountSignUpPage {
  background: #fff;
  padding: 0 20px 80px;

  //#region Shared

  .BubbleHeader .blue-bubbles {
    margin-top: 0;
  }

  form {
    max-width: 375px;
    margin: auto;
    .card {
      padding: 20px 40px 60px;
      border: 1px solid #eee;
      border-radius: 3px;
      @media screen and (max-width: 674px) {
        padding: 20px 20px 40px;
      }
    }
  }

  input:not([type='checkbox']):not([type='radio']),
  select,
  textarea {
    background: none;
    border: none;
    border-bottom: 1px solid #bbb;
    border-radius: 0;
    color: #555555;
    &:focus {
      border-color: #4668f4;
    }
  }
  .Input:not(.checkbox).has-value input:focus + label,
  .Input.has-value textarea:focus + label {
    color: #4668f4;
  }
  textarea {
    border: 1px solid #bbb;
    min-height: 120px;
  }

  .footer {
    margin-top: 25px;
    text-align: center;
    button + button {
      margin-left: 15px;
    }
    button.secondary {
      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
      }
    }
  }

  .summary {
    padding: 8px 30px;
    background: #ffffffe3;
    backdrop-filter: blur(3px);
    box-shadow: 0 0 4px 4px rgba(0,0,0,0.04);
    position: fixed;
    top: 55px;
    left: 0;
    right: 0;
    z-index: 10;
    @media screen and (min-width: 675px) {
      div {
        max-width: 200px;
        margin: auto;
      }
    }
  }

  //#endregion Shared

  //#region Step 1

  .corporate-account {
    margin-top: 10px;
    position: relative;
    z-index: 2;
  }

  .corparate-account-parent-container {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: auto;
    background: #fff;
    padding: 30px 40px;
    border-radius: 5px;
    box-shadow: 0 0 6px 1px #0002;
  }

  .corporate-account-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    img {
      height: 100px;
    }
    img.logo-shinerite {
      height: 160px;
    }
    h1 {
      margin: 0 10px;
    }
  }

  .corporate-welcome-text-container{
    border-radius: 5px;
    background: linear-gradient(128deg,#35d88e,#39a8eb);
    background-image: linear-gradient(128deg, rgb(53, 216, 142), rgb(57, 168, 235));
    color: white;
    padding: 20px;
    p {
      text-align: center;
      font-weight: 600;
      font-size: 20px;
      margin: 0;
      padding: 0;
    }
    p + p {
      padding-top: 5px;
    }
  }

  .basic-info-section {
    .basic-info-form-header {
      max-width: 800px;
      margin: 0 auto 10px;
      text-align: center;
      font-size: 15px;
      .color-divider {
        width: 100px;
        height: 4px;
      }
      & + form {
        padding-bottom: 70px;
      }
    }
    .Form {
      max-width: 325px;
      margin: auto;
      .footer {
        button {
          width: 100%;
          margin-top: 0;
        }
        button + button {
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }
  }

  .basic-card {
    background-color: #f1f7ed;
    transform: translateY(-60px);
  }

  @media screen and (max-width: 999px) {
    .corporate-account-info-container {
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
      h1 {
        margin: 0 0 10px 0;
      }
    }
  }

  //#endregion Step 1

  //#region Calendar

  .Calendar {
    height: 700px;
    max-width: 1000px;
    margin: 30px auto 0;
    button {
      margin-top: 0;
    }
    .rbc-toolbar {
      position: relative;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 5px;
      & > :nth-child(3) {
        display: none;
      }
      .rbc-btn-group {
        margin-top: 0;
        order: 0;
      }
      .rbc-btn-group:first-child button:first-child {
        display: none;
      }
      .rbc-toolbar-label {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        font-size: 95%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      button {
        border: none;
      }
      button:active, 
      button.rbc-active {
        box-shadow: none;
        background-color: #0000;
      }
      button:focus {
        background-color: #0000;
      }
    }
  }
  .calendar-placeholder {
    max-width: 1000px;
    margin: auto;
    padding: 30px 0px 20px;
    div {
      animation: placeholder 2s ease-in-out infinite;
    }
    .div-1 {
      height: 33px;
      margin-bottom: 10px;
    }
    .div-2 {
      height: 16px;
      margin-bottom: 6px;
    }
    .div-3 {
      height: 678px;
    }
  }

  @media (max-width: 999px) {
    .Calendar {
      .rbc-toolbar {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0;
        .rbc-toolbar-label {
          padding: 0;
          text-align: right;
          position: static;
          transform: none;
        }
      }
    }
  }

  //#endregion Calendar

  //#region Your Info
  
  .vehicle-info-divider {
    margin: 50px auto;
  }
  
  //#endregion Your Info
  
  //#region Terms
  
  .cart-wrapper,
  .terms-wrapper {
    border: 1px solid #eee;
    border-radius: 3px;
    max-width: 500px;
    margin: auto;
  }
  .terms-wrapper {
    margin-top: 40px;
    padding: 20px;
  }
  .Cart {
    padding-bottom: 40px;
  }
  .Terms {
    color: #bbb;
    font-size: 85%;
  }

  @media screen and (max-width: 999px) {
    .cart-wrapper {
      padding: 20px;
    }
    .Cart {
      padding-bottom: 30px;
    }
  }
  
  //#endregion Terms

  .cart-divider {
    margin: 60px auto 50px;
  }

  .terms-divider {
    margin: 60px auto;
  }

  .payment-step {
    .Cart {
      max-width: 500px;
      margin: auto;
      border: 1px solid #ddd;
      border-radius: 3px;
    }
    .Terms {
      max-width: 500px;
      margin: 50px auto;
      border: 1px solid #ddd;
      border-radius: 3px;
      padding: 15px 15px 30px;
      font-size: 14px;
      color: #aaa;
    }
  }

  .done-wrapper {
    max-width: 500px;
    margin: auto;
  }

  .done-message {
    font-size: 18px;
    line-height: 1.7;
    max-width: 600px;
    margin: auto;
  }

  .password-card {
    margin-top: 50px;
    border: 1px solid #eee;
    border-radius: 3px;
    padding: 30px;
    p {
      margin-top: 0;
    }
    button {
      width: 100%;
    }
  }

  @media screen and (max-width: 674px) {
    .ProductGrid {
      transform: translateY(-70px);
    }
    .corparate-account-parent-container {
      padding-left: 15px;
      padding-right: 15px;
      background: linear-gradient(#fff, #fff 30%, #fffe);
    }
    .corporate-account-info-container {
      img {
        width: auto;
        height: auto;
        max-width: 44%;
        flex: 0 0 40%;
      }
      h1 {
        flex: 0 0 30px;
      }
      img.logo-shinerite {
        height: auto;
      }
    }
    .corporate-welcome-text-container p {
      font-size: 18px;
    }
    .corporate-welcome-text-container p + p {
      padding-top: 15px;
    }
    .basic-info-section {
      position: relative;
      margin: -75px -3px 0;
      box-shadow: 0 0 6px 1px #0002;
      border-radius: 5px;
      padding: 15px 15px 40px;
      background-color: #fff;
      h2 {
        font-size: 20px;
        margin: 0;
        padding: 20px 0 20px 0;
      }
      .Form {
        padding-bottom: 0;
      }
      .footer {
        margin-bottom: 0;
      }
    }
    .section.product-info {
      .BubbleHeader {
        .blue-bubbles {
          min-height: 250px;
        }
        .bubble-text {
          font-size: 20px;
          margin-top: 40px;
        }
      }
      .ProductGrid {
        transform: translateY(-20px);
      }
    }
  }
}
