.AppointmentForm {
  max-width: 500px;
  label {
    flex: 0 0 120px;
  }
}

.AppointmentForm_Portal {
  height: 100%;
  padding: 20px;
  .Calendar {
    height: calc(100% - 60px);
    .rbc-toolbar {
      position: relative;
      justify-content: space-between;
      .rbc-toolbar-label {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .rbc-month-view {
      height: calc(100% - 45px);
    }
  }
  .ActionBar {
    padding: 20px 0 0 0;
    .Button {
      padding: 10px 30px;
    }
  }
}

@media screen and (max-width: 999px) {
  .AppointmentForm_Portal {
    height: auto;
    .Calendar {
      height: 700px;
      .rbc-toolbar {
        flex-direction: column;
        .rbc-toolbar-label {
          position: static;
          left: auto;
          transform: none;
        }
      }
      .rbc-month-view {
        height: calc(100% - 80px);
      }
    }
    .ActionBar {
      .Button {
        width: 100%;
        justify-content: center;
      }
    }
  }
}