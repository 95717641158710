.DeleteButton {
  display: inline-block;
  padding: 10px 30px;
  background: red;
  color: white;
  &:not(:disabled):hover {
    background: darken($color: red, $amount: 10%);
  }
  &:not(:disabled):active,
  &:not(:disabled):focus {
    background: darken($color: red, $amount: 20%);
  }
  &.sm {
    padding: 7px 22px;
  }
}
