.Faq {
  .faq-container {
    padding: 50px 20px;
    display: grid;
    grid-template-columns: repeat(2, minmax(400px, 600px));
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    @media screen and (max-width: 1100px) {
      padding-left: 0;
      padding-right: 0;
      grid-template-columns: 1fr;
    }
  }
  ul {
    list-style-type: none;
  }
  .container > ul {
    padding-left: 0;
  }
  li {
    line-height: 1.6em;
  }
}

.FaqSection {
  &.expanded {
    .toggle {
      transform: rotate(180deg);
    }
  }
  .header {
    display: flex;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    user-select: none;
    position: relative;
    &:hover {
      background: #fafafa;
    }
    h2 {
      margin: 0;
      padding: 0;
      font-size: 27px;
    }
    .icon {
      margin-left: 15px;
      width: 25px;
      height: 25px;
    }
    .toggle {
      width: 18px;
      height: 9px;
      position: absolute;
      right: 15px;
      top: calc(50% - 4px);
      transition: transform 150ms;
    }
  }
  .body {
    &.expanded {
      animation: expand-down 100ms ease-in-out;
    }
    & > ul {
      margin: 0;
      padding: 20px;
      font-weight: 600;
    }
    ul ul {
      font-weight: 400;
      padding: 0;
    }
    p {
      margin: 25px 0;
    }
    li {
      line-height: 35px;
    }
  }
}
