.ActionBar {
  padding: 30px 0;
  display: flex;
  align-items: center;
  & > * {
    margin: 0;
  }
  & > * + * {
    margin-left: 20px;
  }
}

@media screen and (max-width: 674px) {
  .ActionBar {
    flex-direction: column;
    align-items: flex-start;
    & > * + * {
      margin-top: 20px;
      margin-left: 0;
    }
  }
}
