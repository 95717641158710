* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
}
.formfield.checkbox {
  label {
    margin-left: 5px;
  }
}
.formfield + .formfield {
  margin-top: 15px;
}
a.plain {
  color: #555;
  text-decoration: none;
}

.form-centered {
  max-width: 350px;
  margin: 10vh auto 0;
  .Field label {
    width: 100px;
  }
  .Form .Field + .Field {
    margin-top: 10px;
  }
}
.gridtable {
  .thead {
    border-bottom: 1px solid #eee;
  }
  .th {
    font-weight: bold;
    white-space: nowrap;
  }
  .tr {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-column-gap: 15px;
    &.large {
      .td {
        padding-top: 25px;
        padding-bottom: 25px;
      }
    }
  }
  .td, .th {
    padding: 3px 0;
  }
}
.gridfields {
  & > * {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 20px;
    & > span:first-child {
      color: #aaa;
    }
  }
  & > * + * {
    margin-top: 10px;
  }
}

.rbc-overlay {
  z-index: 2000 !important;
}

@media screen and (max-width: 399px) {
  .form-centered {
    margin-left: 20px;
    margin-right: 20px;
  }
}
