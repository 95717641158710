.EventList {
  .AddButton {
    margin-left: 20px;
  }
  .Calendar {
    height: calc(100vh - 135px);
    min-height: 450px;
  }
}

@media screen and (max-width: 674px) {
  .EventList {
    .Calendar {
      height: calc(100vh - 90px);
    }
  }
}
