.ProductGridWrapper {
  max-width: 1000px;
  margin: 100px auto 60px auto;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 40px;
  @media (max-width: 940px) {
    padding: 40px 15px 0px;
    max-width: calc(100vw - 20px);
  }

  & > .header {
    font-size: 28px;
    padding: 0 0 40px;
    text-align: center;
    display: block;
  }
}

.LandingPage .ProductGridWrapper .ProductGrid .group:last-child {
  .color-divider {
    display: none;
  }
}