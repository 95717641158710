.Nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 55px;
  background: #fff;
  z-index: 100;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  .left {
    height: 100%;
    display: flex;
    align-items: center;
    a, .pseudo-link {
      padding: 11px 0;
      margin-right: 15px;
    }
    svg {
      height: 100%;
      width: 150px;
    }
  }
  a, .pseudo-link {
    display: inline-block;
    text-decoration: none;
    height: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #151515;
    img {
      height: 100%;
    }
  }
  .right {
    display: flex;
    height: 100%;
    a {
      display: flex;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }
    a:not(.primary) {
      height: calc(100% + 1px);
      padding: 0 12px;
      color: #151515;
      border-top: 2px solid #0000;
      border-bottom: 2px solid #0000;
      transition: border-bottom 100ms ease-in-out;
      &:hover {
        color: darken(#151515, 5%);
        border-bottom: 2px solid darken(#151515, 5%);
      }
      &.active {
        color: darken(#151515, 10%);
        border-bottom: 2px solid darken(#151515, 10%);
      }
    }
    a.button {
      margin-left: 15px;
      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
    }
    a + a {
      margin-left: 3px;
    }
    .button.primary {
      height: 42px;
      align-self: center;
      border-radius: 3px;
      border: none;
    }
    .Button {
      padding: 10px;
      background: #0000;
      transition: all 100ms ease-in-out;
      &:hover {
        background: #0000000b;
      }
    }
    .greeting {
      align-self: center;
      margin-right: 10px;
      @media (max-width: 674px) {
        max-width: 40vw;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

.NavActionMenu {
  .menu {
    a, a.button.primary {
      display: flex;
      align-items: center;
      padding: 15px 20px;
      color: #555;
      text-decoration: none;
      background: #0000;
      border: none;
      border-bottom: 1px solid #eee;
      &:hover, &:active, &:focus {
        color: #333;
        background: #f8f8f8;
      }
      img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }
    a.button.primary {
      color: #4668f4;
    }
  }
}

@media screen and (max-width: 674px) {
  .logged-in.is-admin .Nav {
    display: none;
  }
}
