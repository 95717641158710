.rbc-event {
  &:not(.rbc-selected) {
    background-color: #3174addd;
    &:hover {
      background-color: #3174adee;
    }
    &:active, &:focus {
      background-color: #3174adff;
    }
    &.corporate_wash {
      background-color: #887aff;
      &:hover {
        background-color: darken(#887aff, 5%);
      }
      &:active, &:focus {
        background-color: darken(#887aff, 10%);
      }
    }
    &.available_time {
      background-color: #68b668;
      &:hover {
        background-color: darken(#68b668, 5%);
      }
      &:active, &:focus {
        background-color: darken(#68b668, 10%);
      }
    }
    &.timeoff, &.holiday {
      background-color: #cf7f6b;
      &:hover {
        background-color: darken(#cf7f6b, 5%);
      }
      &:active, &:focus {
        background-color: darken(#cf7f6b, 10%);
      }
    }
    &.cancelled {
      background-color: #3174ad99;
      &:hover {
        background-color: #3174adaa;
      }
      &:active, &:focus {
        background-color: #3174adbb;
      }
    }
  }
  &:focus {
    outline: none;
  }
  &.rbc-selected {
    background-color: #3174adff;
    &.corporate_wash {
      background-color: #887aff;
    }
    &.cancelled {
      background-color: #3174ad99;
    }
    &.available_time {
      background-color: darken(#68b668, 10%);
    }
    &.timeoff, &.holiday {
      background-color: darken(#cf7f6b, 10%);
    }
  }
  &.unavailable {
    opacity: 0.7;
    pointer-events: none;
  }
}

.Calendar {
  .rbc-day-bg {
    cursor: pointer;
  }
  .rbc-toolbar button:hover {
    background-color: #fafafa;
  }
  .rbc-toolbar button:focus {
    background-color: #f5f5f5;
  }
  .rbc-toolbar button:active:hover,
  .rbc-toolbar button:active:focus,
  .rbc-toolbar button.rbc-active:hover,
  .rbc-toolbar button.rbc-active:focus {
    background-color: #fafafa;
    color: #4668f4;
  }
  .rbc-toolbar button:active,
  .rbc-toolbar button.rbc-active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.04);
    background-color: #f5f5f5;
    color: #4668f4;
  }
  &.readonly {
    .rbc-day-bg {
      cursor: initial;
    }
  }
  .rbc-today {
    background-color: darken(#eaf6ff, 3%);
  }
}

@media screen and (max-width: 999px) {
  .Calendar {
    .rbc-toolbar {
      flex-direction: column;
      align-items: center;
      .rbc-toolbar-label {
        order: 0;
        font-size: 18px;
        font-weight: 600;
      }
      .rbc-btn-group:first-child {
        order: 1;
      }
      .rbc-btn-group {
        margin-top: 5px;
      }
      button {
        font-size: 15px;
      }
    }
  }
}