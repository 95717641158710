.CorporateBookingPage {
  .get-started-wrapper {
    display: flex;
    justify-content: center;
    button {
      margin-top: 0;
      padding: 20px 50px;
    }
  }
  .confirm-car {
    max-width: 500px;
    margin: 30px auto 40px;
    padding: 15px;
    box-shadow: 0 0 6px 1px #0002;
  }
  .Cart {
    padding: 15px;
    box-shadow: 0 0 6px 1px #0002;
  }
}
