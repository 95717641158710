.SubmitButton {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 0;
  background: #4668f4;
  color: white;
  margin-top: 25px;
  &:not(:disabled):hover {
    background: darken(#4668f4, 5%);
  }
  &:not(:disabled):active, &:not(:disabled):focus {
    background: darken(#4668f4, 10%);
  }
}