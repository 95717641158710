.SaveButton {
  display: inline-block;
  padding: 10px 30px;
  margin: 0;
  background: #4668f4;
  color: white;
  &:not(:disabled):hover {
    background: darken(#4668f4, 5%);
  }
  &:not(:disabled):active,
  &:not(:disabled):focus {
    background: darken(#4668f4, 10%);
  }
  &.sm {
    padding: 7px 22px;
  }
}
