.TitleBar {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  h2 {
    margin: 0;
    padding: 0;
    font-size: 18px;
  }
  .BackButton {
    margin-left: -15px;
    padding: 1px 15px;
  }
  .AddButton {
    margin-left: 20px;
  }
  .AddButton + * {
    margin-left: 20px;
  }
}

@media screen and (max-width: 674px) {
  .TitleBar {
    h2 {
      font-size: 22px;
    }
  }
}
