.AppointmentList {
  .list.list-primary a {
    grid-template-columns: minmax(min-content, 180px) minmax(min-content, 400px) minmax(min-content, 300px) 80px;
  }
  .actions {
    display: flex;
    align-items: center;
    & > * + * {
      margin-left: 15px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .AppointmentList {
    .list.list-primary a {
      padding: 15px;
      display: block;
    }
  } 
}