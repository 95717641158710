.AddOnGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 120px);
  justify-content: space-between;
  grid-gap: 30px;
  max-width: 600px;
  margin: 0 auto 50px;

  .AddOn {
    border-radius: 5px;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: transform 100ms cubic-bezier(0.71, 0.55, 0.62, 1.57);
    &:not(.disabled) {
      cursor: pointer;
    }
    &.color-50bda3 {
      &:not(.selected) {
        svg, svg * { fill: #50bda388; }
      }
      svg, svg * { fill: #50bda3; }
    }
    &.color-1894a2 {
      &:not(.selected) {
        svg, svg * { fill: #1894a288; }
      }
      svg, svg * { fill: #1894a2; }
    }
    &.color-4468f1 {
      &:not(.selected) {
        svg, svg * { fill: #4468f155; }
      }
      svg, svg * { fill: #4468f1bb; }
    }
    &:hover:not(.selected):not(.disabled) {
      border-color: #caddf3;
      .title {
        color: #333;
      }
    }
    &.selected {
      border-color: #4668f4;
      background-color: #fff;
      box-shadow: 0 0 2px -3px #4668f411;
      transform: scale(1.04);
      .title {
        color: #222;
      }
    }
    .img-row {
      padding: 5px;
      display: flex;
      justify-content: center;
      height: 80px;
      svg {
        width: 70px;
        height: 70px;
      }
    }
    .title {
      text-align: center;
      padding: 5px 10px 40px;
      user-select: none;
    } 
    .price {
      color: #bbb;
      text-align: center;
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      user-select: none;
    }
  }
}

// @media screen and (max-width: 950px) {
//   .AddOnGrid {
//     grid-template-columns: repeat(auto-fit, 100%);
//   }
// }

@media screen and (max-width: 650px) {
  .AddOnGrid {
    grid-gap: 20px 5px;
    margin-bottom: 10px;
    .AddOn {
      .img-row {
        height: 65px;
        svg {
          width: 55px;
          height: 55px;
        }
      }
      .title, .price {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 410px) {
  .AddOnGrid {
    grid-template-columns: repeat(auto-fill, 150px);
    .AddOn {
      width: 150px;
      height: 80px;
      .img-row {
        width: 80px;
        height: 80px;
        margin: 0;
        padding: 10px 0;
        position: absolute;
        svg {
          width: 60px;
          height: 60px;
        }
      }
      .title {
        position: absolute;
        margin: 0;
        bottom: 0;
        right: 0;
        width: calc(100% - 65px);
        padding: 5px;
      }
      .price {
        top: 0;
        right: 0;
        bottom: auto;
        left: auto;
        transform: none;
        margin: 0;
        padding: 2px 5px;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .AddOnGrid {
    margin-left: -15px;
    margin-right: -15px;
  }
}
