.SelectableList {
  display: grid;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  grid-template-columns: repeat(auto-fill, 200px);
  .item {
    border-radius: 3px;
    transform: translateY(0);
    transition: all 100ms ease-in-out;
    padding: 20px;
    cursor: pointer;
    background: #fff;
    box-shadow: 0 0 20px 10px #00000004;
    &:hover {
      box-shadow: 0 0 20px 10px #0001;
      transform: translateY(-1px);
    }
    &.active {
      background: #dbf8ff;
    }
    & > div + div {
      color: #aaa;
    }
    & > div:nth-child(2) {
      margin-top: 20px;
    }
  }
}
