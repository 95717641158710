.LandingPageBase {
  font-size: 18px;
  a.sign-up {
    margin-top: 50px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 25px;
    box-shadow: 0 10px 30px 5px #0002;
    background-color: #00abf4;
    text-transform: uppercase;
    font-size: 90%;
    font-weight: 600;
    letter-spacing: 1px;
    &:hover {
      background-color: #2020fc;
    }
    &:active,
    &:focus {
      background-color: #2020f8;
    }
  }
  .section {
    border-bottom: 1px solid #eee;
    &:nth-child(even) {
      background-color: #f9f9f9;
    }
  }
  .main-section {
    border-bottom: none;
  }
  .container {
    max-width: 1300px;
    margin: auto;
    padding: 120px 20px;
  }
  .brand-container {
    position: relative;
    max-width: 1300px;
    margin: auto;
    padding: 50px 20px 150px;
    background-size: cover;
    background-position-y: -400px;
    text-align: center;
    color: #4736ca;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .left {
      min-width: 450px;
      flex: 1 1 60%;
    }
    .right {
      min-width: 450px;
      flex: 1 1 40%;
    }
    img {
      max-width: 100%;
      object-fit: contain;
    }
    @media screen and (max-width: 500px) {
      display: block;
      .left, .right {
        min-width: auto;
        width: calc(100vw - 40px);
      }
      img {
        width: 100%;
      }
    }
  }
  h3 {
    font-size: 27px;
    margin-top: 0;
  }
  .description {
    color: #aaa;
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 50px;
  }
  .add-ons {
    .container {
      max-width: 800px;
    }
    table {
      width: 100%;
    }
    th, td:not(:first-child) {
      text-align: right;
      padding: 8px;
    }
  }
  .heading {
    padding: 120px 0;
    color: #fff;
    background-color: #000;
    text-align: center;
    background-image: url(../../images/exhaust.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    h1 {
      text-transform: uppercase;
      font-size: 40px;
      letter-spacing: 2px;
    }
    .container {
      padding: 0 20px;
    }
  }
}

@media screen and (max-width: 764px) {
  .OurProcess {
    .container {
      padding-top: 50px;
    }
  }
}