.CovidPage {
  padding-bottom: 50px;
  .banner {
    background-image: url(../../../assets/covid-banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px 100px;
    h1 {
      margin: 0 auto;
      font-size: 40px;
      text-align: center;
    }
    .stamp {
      margin: 30px 0;
    }
    a.button.primary {
      background: rgb(0, 146, 203);
      border-radius: 3px;
      padding: 18px 40px;
      font-size: 14px;
    }
  }
  .point {
    max-width: 900px;
    padding: 15px;
    margin: auto;
    .header {
      display: flex;
      flex-wrap: wrap;
    }
    .img-wrapper {
      flex: 0 0 170px;
    }
    .title {
      flex: 1 1 70%;
      h2 {
        color: rgb(20, 52, 75);
        margin: 0;
        font-size: 33px;
        font-weight: 900;
      }
      h3 {
        color: rgb(0, 146, 203);
        margin: 10px 0 0;
        font-size: 20px;
      }
    }
    .body {
      line-height: 1.5;
      font-size: 17px;
    }
  }
  .images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 900px;
    padding: 15px;
    margin: auto;
    .image {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 20px 20px 0;
      img {
        width: 60px;
        margin-bottom: 10px;
      }
      span {
        color: rgb(0, 2, 33);
      }
    }
  }
}

@media screen and (max-width: 725px) {
  .CovidPage {
    .point {
      .img-wrapper {
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
      }
      .title {
        margin-top: 15px;
        flex: 0 0 100%;
        text-align: center;
      }  
    }
  }
}