.CustomerDetail {
  .invoice {
    display: flex;
    max-width: 800px;
    & > * {
      padding: 15px 25px;
      flex: 1 1 30%;
    }
    .download {
      text-align: right;
    }
    img {
      width: 17px;
      height: 17px;
    }
  }
  .invoice + .invoice {
    border-top: 1px solid #f0f0f0;
  }
  .subscription-info {
    & > div {
      display: grid;
      grid-template-columns: 120px 300px;
      padding: 5px 0;
    }
  }
  .subscription-info + .subscription-info {
    margin-top: 20px;
  }
  .change-plan {
    margin-top: 0;
  }
  .list {
    .car, .appointment, .address {
      padding: 20px;
      display: grid;
      align-items: center;
      // &:hover {
      //   color: #000;
      //   background: #4668f406;
      // }
      .default {
        color: #bbb;
      }
    }
    .car, .address {
      grid-template-columns: minmax(min-content, 500px) 100px;
    }
    .appointment { 
      grid-template-columns: 180px minmax(min-content, 350px) minmax(min-content, 300px);
    }
    .car + .car,
    .appointment + .appointment,
    .address + .address {
      border-top: 1px solid #f0f0f0;
    }
    @media screen and (max-width: 800px) {
      .car, .appointment {
        grid-template-columns: 1fr;
        row-gap: 10px;
      }
    }
  }
}

.BasicInfo {
  .header {
    padding-top: 75px;
  }
  .ActionBar {
    padding-bottom: 0;
  }
  .Form {
    max-width: 500px;
    label {
      flex: 0 0 180px;
    }
  }
}

.ChangePlan {
  .plans {
    display: flex;
    flex-wrap: wrap;
  }
  .plan {
    margin: 0 20px 20px 0;
    padding: 30px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background: #fff;
    transition: all 100ms ease-in-out;
    box-shadow: 0 0 20px 10px #00000004;
    &:hover {
      box-shadow: 0 0 20px 10px #0001;
      transform: translateY(-1px);
    }
    &.active {
      background: #dbf8ff;
    }
    .price {
      margin-top: 20px;
    }
  }
  .Field {
    max-width: 500px;
    label {
      flex: 0 0 125px;
    }
  }
}
