.PaymentForm {
  .row {
    display: flex;
    & > * {
      flex: 1 1 100%;
    }
    & > * + * {
      margin-left: 10px;
    }
    .half-width {
      flex: 1 1 50%;
    }
    .quarter-width {
      flex: 1 1 25%;
    }
    .Input {
      padding-top: 15px;
      input:not([type='checkbox']):not([type='radio']),
      select,
      textarea {
        background: none;
        border: none;
        border-bottom: 1px solid #bbb;
        border-radius: 0;
        color: #555555;
        &:focus {
          border-color: #4668f4;
        }
      }
      input::placeholder {
        color: #bbb;
      }
      input:focus + label {
        color: #4668f4;
      }
    }
  }
  .postal-code {
    min-width: 90px;
  }
  .row + .row {
    margin-top: 20px;
  }
  form {
    padding: 30px 0 20px;
  }
  .element {
    padding-top: 15px;
    position: relative;
    &.error {
      label {
        color: red;
      }
      .baseline {
        background-color: red;
      }
    }
    &.focus {
      label {
        color: #4668f4;
      }
      .baseline {
        background-color: #4668f4;
      }
    }
    .input-wrapper {
      padding: 6px 12px;
    }
    label {
      position: absolute;
      top: 0px;
      left: 0px;
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      color: #bbb;
    }
    .baseline {
      width: 100%;
      height: 1px;
      background-color: #bbb;
    }
  }
  .StripeElement {
    margin: 0;
  }
  .InnerPaymentForm {
    margin-bottom: 20px;
  }
  .same-address {
    padding: 5px 0 10px;
  }
}