.Field {
  display: flex;
  align-items: center;
  label {
    margin-right: 10px;
  } 
  input:not([type="checkbox"]):not([type="radio"]) {
    flex: 1;
  }
  .Input, select {
    flex: 1 1 100%;
  }
  .duration {
    display: flex;
    align-items: center;
    flex: 1 1 200px;
    input {
      flex: 0 0 100px !important;
    }
    select {
      flex: 1 1 100px;
      margin-left: 10px;
    }
  }
  &.no-placeholders .Input {
    padding-top: 0;
  }
  &.static-placeholders .Input {
    label {
      top: 0px;
      left: 0px;
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
  &.checkboxgroup {
    & > label {
      align-self: flex-start;
    }
  }
  .checkboxgroup {
    label {
      display: flex;
      align-items: center;
    }
  }
  label input {
    margin-right: 7px;
  }
  .textarea {
    white-space: pre-line;
  }
}

@media screen and (max-width: 499px) {
  .Field {
    flex-direction: column;
    align-items: initial;
    label {
      flex: auto !important;
      margin: 0 0 7px 0;
    }
  } 
}