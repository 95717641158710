.Footer {
  background-image: url('../../../../assets/clean-exterior.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: white;
  font-weight: 500;
  padding: 15px;

  a {
    text-decoration: none;
  }
  
  .tint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2f55cf;
    opacity: 0.8;
  }

  .container {
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: relative;
    margin: auto;
    h3 {
      font-weight: 500;
    }
    a {
      color: #fff;
    }
  }
  .copyright-text {
    margin-top: 70px;
    font-size: 14px;
  }

  .company {
    img {
      width: 170px;
    }

    a {
      display: inline-block;
      padding: 10px 25px;
      border-radius: 3px;
      background: none;
      color: #fff;
      border: 1px solid #fff;
      margin-top: 0;
      font-size: 16px;
      font-weight: 600;
      &:hover,
      &:active,
      &:focus {
        background: none;
        transform: scale(1.03);
        transition: transform 100ms ease-in-out;
      }
    }
  }

  .terms-conditions {
    cursor: pointer;
    transition: color 100ms;
    &:hover {
      color: #ccc;
    }
  }

  .social {
    margin-top: 5px;
    a,
    i {
      font-size: 27px;
      color: white;
    }
    a + a {
      margin-left: 15px;
    }
  }

  .social {
    p {
      margin-top: 15px;
    }
  }
  .phone {
    p {
      font-size: 22px;
      margin-top: 5px;
    }
  }
  .email {
    .contact-info {
      font-size: 22px;
    }
    .sub-text {
      margin-top: 10px;
      font-weight: 400;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .company {
      img {
        margin-top: 40px;
      }
      p {
        width: 270px;
        margin: 15px auto 0;
        line-height: 1.5;
      }
      a {
        margin-top: 30px;
      }
    }
    .social {
      margin-top: 50px;
    }
    .phone {
      margin-top: 40px;
    }
    .email {
      margin-top: 40px;
    }
  }

  @media screen and (min-width: 769px) {
    & {
      background-image: url('../../../../assets/special.jpg');
      background-position: center;
    }
    .row {
      flex: 1;
      display: flex;
      justify-content: space-between;
      padding-top: 40px;
    }
    .company {
      p {
        max-width: 250px;
        line-height: 1.6;
        margin: 10px 0 30px;
      }
    }
  }

  .container {
    @media screen and (min-width: 1216px) { max-width: 1152px; }
    @media screen and (min-width: 1408px) { max-width: 1344px; }
  }
}
