.EventForm {
  padding: 20px;
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    .Form {
      flex: 1 1 500px;
      max-width: 700px;
    }
  }
  .add-on-wrapper {
    max-width: 1000px;
    flex: 1 1 500px;
    margin-left: 100px;
    h3 {
      font-size: 18px;
      font-weight: 600;
      margin: 0 0 40px 0;
    }
  }
  label {
    flex: 0 0 200px;
  }
  .header {
    margin: 0 0 20px0;
  }
  .unavialable-message {
    font-size: 13px;
    color: #888;
  }
  .Field + .Field {
    margin-top: 20px;
  }
  .section {
    margin-top: 50px;
    h4 {
      margin: 0 0 20px 0;
    }
  }
  input:disabled:not([type="checkbox"]), textarea:disabled {
    border: none;
    background-color: #0000 !important;
    appearance: none;
    color: #333;
    padding: 0;
  }
  .textarea {
    color: #333;
  }
  textarea {
    height: 150px;
    resize: none;
  }
  .extra-info {
    label {
      align-self: flex-start;
    }
  }
}

.EventFormWrapper {
  .header {
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
    select {
      width: 250px;
      border: none;
      font-size: 18px;
      font-weight: 600;
      padding: 0;
      option {
        font-size: 16px;
      }
    }
  }
}

.GenericEventForm {
  max-width: 700px;
}

@media screen and (max-width: 1200px) {
  .EventForm {
    .wrapper {
      display: block;
    }
    .Form {
      max-width: 600px;
    }
    .add-on-wrapper {
      margin-top: 60px;
      margin-bottom: 30px;
      margin-left: 0;
      max-width: 700px;
    }
  }
}
