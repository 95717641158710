.CustomerHome {
  & > .summary {
    padding: 0 10px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 55px;
    left: 0;
    right: 0;
    height: 30px;
    background: #fff;
    box-shadow: 0 0 4px 4px rgba(0,0,0,0.04);
    z-index: 10;
    a {
      font-size: 90%;
      text-decoration: none;
      margin-left: 8px;
    }
  }
  .customer-content {
    padding: 20px;
    background: #f9f9f9;
    min-height: calc(100vh - 55px);

    & > .Footer {
      margin: 0 -20px -20px;
    }
  }
  .LandingPage {
    padding-top: 30px;
  }
}

.is-mobile .CustomerHome {
  .hero a.signup.sticky {
    top: 85px;
  }
}

@media screen and (max-width: 674px) {
  .CustomerHome {
    padding-top: 55px;
  } 
}