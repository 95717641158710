.DiscreteProgessBar {
  display: flex;
  align-items: center;
  .step {
    flex: 0 1 58px;
    padding: 5px;
    background: #28d78c;
    color: #fff;
    font-size: 11px;
    font-weight: 600;
    border-radius: 1px;
    text-align: center;
    transition: opacity 100ms;
    opacity: 1;
    white-space: nowrap;
    &:not(.active) {
      opacity: 0.5;
    }
  }
  .bar {
    flex: 1;
    height: 2px;
    background: #28d78c;
    margin: 0 5px;
  }
}